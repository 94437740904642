class APIService {
	constructor(Restangular, ToastService, $window,$state, $translate, $auth) {
		'ngInject';
		//content negotiation
		let headers = {
			//'Content-Type': 'application/json',
			'Accept': 'application/x.laravel.v1+json',
			//'X-Api-Language': 'tr'
		};

		this.$auth = $auth;
		this.$translate = $translate;
		var parent = this;
		return Restangular.withConfig(function(RestangularConfigurer) {
			RestangularConfigurer
				.setBaseUrl(window.__env.apiUrl+'/api/')
				.setDefaultHeaders(headers)
				.setErrorInterceptor(function(response) {
					if (response.status === 422 || response.status === 401) {
						for (let error in response.data.errors) {
							return ToastService.error(response.data.errors[error][0]);
						}
					}
					if (response.status === 403) {
						return ToastService.error(parent.$translate.instant('NOT_AUTHORIZED'));
					}

					if (response.status === 401) {

						$state.go('app.login');
					//return ToastService.error(response.statusText)
					}

					if (response.status === 500) {
						if (response.data !== undefined && response.data.error !== undefined ) {
							return ToastService.error(response.data.error)
						} else {
							return ToastService.error(parent.$translate.instant('UNEXPECTED_ERROR_OCCURRED'));
						}
					}
				})
				.addFullRequestInterceptor(function(element, operation, what, url, headers) {
					let token = $window.localStorage.satellizer_token;
					if (token) {
						headers.Authorization = 'Bearer ' + token;
					}
					if (!url.includes('upload/file')) {
						headers['Content-Type'] = 'application/json';
					}
				}).addResponseInterceptor(function(data, operation, what, url, response, deferred) {
					if (response.headers('Content-Type') === 'application/pdf'){
						return response
					}
				    var extractedData;

				    if (operation === "getList") {
				    	extractedData = response.data.data.data;
					} else {
						//extractedData = response.data.data ?? data;
						extractedData = response.data;
				    }
					// todo : apiden gelen response data nesnesini zenginleştrir eğer apiden response diye bir field gelirse onu önceler

					if (response.status === 204 || response.status === 201){
						extractedData = {...extractedData,
							response:{
								status: response.status,
								statusText: response.statusText,
								xhrStatus:response.xhrStatus
							}
						}
					}
					if (response.status === 207){
						extractedData = {data:extractedData}
					}

					if (!extractedData?.response) {
                        extractedData.response = {
                            status: response.status,
                            statusText: response.statusText,
                            xhrStatus:response.xhrStatus
                        };
                    }
				    return extractedData;
				})
	//			.setFullResponse(true);
		});
	}
}
APIService.$inject = ["Restangular", "ToastService", "$window","$state", "$translate", "$auth"];
export {APIService};
