import "./upload.scss";
import Cookies from "js-cookie";

class GalleryController {

    constructor(API,ToastService,$translate,$mdBottomSheet, $scope) {
        this.API = API;
        this.$scope = $scope;
        this.$mdBottomSheet = $mdBottomSheet;
        this.ToastService = ToastService;
        this.$translate = $translate;
        this.galleryLength = 0
        this.currentGalleryIndex = 0;
        this.gallery = [];
    }

    $onInit() {
        this.galleryLength = this.gallery.length - 1
    }

    next() {
        ++this.currentGalleryIndex
        if (this.currentGalleryIndex > this.galleryLength) {
            this.currentGalleryIndex = 0;
        }
    }

    prev() {
        --this.currentGalleryIndex
        if (this.currentGalleryIndex <0) {
            this.currentGalleryIndex = this.galleryLength;
        }
    }
    deleteAttachment (image) {
        this.API.all("document/delete")
            .customDELETE(null, {
                documentId: image?.documentId,
                service: image?.service,
                serviceId: image?.serviceId,
            }).then((response) => {
            if (response.response.status === 200) {
                this.gallery = response.data.list.documents;
                this.ToastService.showHtml('success', this.$translate.instant('SUCCESSFUL'))
            }
        })
    }
}

GalleryController.$inject = ["API", "ToastService",  "$translate", "$mdBottomSheet", "$scope"]
const GalleryComponent = {
    templateUrl: "./views/angular/app/components/upload/gallery.component.html",
    controller: GalleryController,
    controllerAs: "vm",
    bindings: {
        gallery: '<'
    },
};
export {GalleryComponent, GalleryController}
