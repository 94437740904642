import {isEmpty, template} from "lodash";
import {openIntroductionVideo} from "../../../utils/introduction-videos";
import "./stock-management.scss";

class StockManagementController {
    constructor(API, $auth, $http, $scope, $rootScope, $stateParams, $state, $translate, $location, $window, $q, $mdBottomSheet, $mdDialog, ToastService, $filter, DialogService, FileUploader) {
        "ngInject";

        this.API = API;
        this.$auth = $auth;
        this.$http = $http;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$translate = $translate;
        this.$location = $location;
        this.$window = $window;
        this.$q = $q;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$mdDialog = $mdDialog;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.$filter = $filter;


        this.disabled = [];
        this.branches = [];
        this.editProductScreen = {};
        this.editProducts = false
        this.salesAnalyticsProductScreen = {};
        this.edit_selected_categories = [];
        this.search_selected_categories = [];
        this.create_product_tags = [];
        this.createFormPackageProducts = [];
        this.editFormPackageProducts = [];
        this.currencyData = [];
        this.searchBrand = [];
        this.branch = {
            image : null,
        }
        this.accordion = [];
        this.expanded = [];

        this.is_end_user_product = true;
        this.wholesale_product = false;
        this.video = false;
        this.product_id = "";
        this.product_code = "";
        this.selectedProduct = {};
        this.subProductScreen = [];
        this.categoryTags = [];

        this.channelsDetail = {};
        this.channelProductDetail = [];
        this.channelProductDetailEdit = [];
        this.variotionsProduct = [];
        this.issueType = 'all';

        this.nextImageIndexProductDefault = 0;
        this.nextImageIndexProduct = [];

        this.changeShelfLocationId = null;
        this.fromStyle = null;
        this.toStyle = null;

        this.lotSerialNumbers = true;
        this.variationDistribution = true;
        this.stockMovement = true;
        this.statisticReport = true;
        this.notAssignedToList = false;
        this.recipeList = false;
        this.incRecipeList = false;
        this.itemsPerPage = 5;
        this.currentPage = 0;
        this.pagedData = [];
        this.hasMoreData = true;
        this.allList = false;

        this.createLotQty = null;
        this.show_selected_categories = false;
        this.create_selling_price_with_vat = false;
        this.selling_price_with_vat = false;

        var self_ = this;

        this.API.all("settings/currency")
            .customGET()
            .then((response) => {
                self_.currencyData = response.data.list;
            });

        this.API.all("settings/get-channels")
            .post()
            .then((response) => {
                this.channels = response.data.channels;
                this.channelProductDetail = this.channels.map(c => {
                    return {
                        checked: true,
                        provider: c.provider,
                        name: c.name,
                        channelId: c.id,
                        vat: c.vat,
                        currency: c.currency,
                        currency_id: c.currency_id,
                        sellingPrice: null,
                        isActive: c.is_active,
                    }
                })
            });

        this.API.all("location")
            .customGET()
            .then((response) => {
                if (response.errors === false) {
                    this.locations = response.data.location;
                }
            });
        this.create_product_tracking_mode = 0;

        this.lotOrSNuData = {};
        this.lot_per_page = 10;
        this.lot_filterArr = {};
        this.lot_page_number = 1;

        this.getLotsForProducts(1, this.lot_per_page);

        this.collapseLotsForProduct = [];
        this.lotAElDisabled = [];

        this.toggleProductsLotsFilterForm = false;

        this.recipeItems=[];
        this.isActive = true;
        this.setFilter();
        this.categoryTagCode = '';
        this.is_active = 1;
        this.isLoading = false;

        this.lotSerialnumbersShow = true;
        this.newWarehousesStatus = true;

    }

    $onInit() {
        this.selectedTabIndex = _.isEmpty(this.$state.params.tab) ? 0 : this.$state.params.tab;


        this.API.all("category/getChildCategories")
          .post()
          .then((response) => {
              this.categories = response.data.categories;
          });

        this.API.all("brand/getList")
          .post()
          .then((response) => {
              this.brands = response.data.brandArray;
          });

        this.API.all("model/getList")
          .post()
          .then((response) => {
              this.models = response.data.modelArray;
          });

        this.API.all("stockUnit/getList")
          .post()
          .then((response) => {
              this.stockUnit = response.data.stockUnit;
          });

        this.API.all('/products/tags').post().then((response) => {
            this.categoryTags = response.data.categoryTags;
        });

        this.create_brand_id = [];
        this.create_selected_categories = [];
        this.search_selected_categories = [];
        this.dropdownCategoriesSettings = {enableSearch: true, scrollableHeight: '400px', scrollable: true};
        this.dropdownCategoriesTranslationsText = {
            buttonDefaultText: this.$filter('translate')('CATEGORY_SELECT'),
            checkAll: this.$filter('translate')('SELECT_ALL'),
            uncheckAll: this.$filter('translate')('REMOVE_STATUS'),
            searchPlaceholder: this.$filter('translate')('SEARCH'),
            dynamicButtonTextSuffix: this.$filter('translate')('CATEGORY_SELECTED'),
        };
        this.dropdownBrandsTranslationsText = {
            buttonDefaultText: this.$filter('translate')('SEARCH_BRAND'),
            searchPlaceholder: this.$filter('translate')('SEARCH'),
            uncheckAll: this.$filter('translate')('REMOVE_STATUS'),
            dynamicButtonTextSuffix: this.$filter('translate')('SELECTED_BRAND'),
        };
        this.dropdownBrandsSettings = {
            enableSearch: true, selectionLimit: 1, scrollableHeight: '400px', scrollable: true,
            smartButtonMaxItems: 1,
            smartButtonTextConverter: function (itemText, originalItem) {
                return itemText;
            }
        };
        this.dropdownModelsTranslationsText = {
            buttonDefaultText: this.$filter('translate')('MODEL_SELECT'),
            searchPlaceholder: this.$filter('translate')('SEARCH'),
            uncheckAll: this.$filter('translate')('REMOVE_STATUS'),
            dynamicButtonTextSuffix: this.$filter('translate')('MODEL_SELECTED'),
        };
        this.dropdownModelsSettings = {
            enableSearch: true, selectionLimit: 1, scrollableHeight: '400px', scrollable: true,
            smartButtonMaxItems: 1,
            smartButtonTextConverter: function (itemText, originalItem) {
                return itemText;
            }
        };

        this.products = {};
        this.current_page = 1;
        this.per_page = 25;
        this.filterArr = {};
        this.lotsForProductFilterArr = {};
        this.lotOrSNuFilterArr = {};
        this.page = 1;
        this.reverse = false;
        this.column = 'updated_at';
        this.sortMode = 'desc';
        this.sortModeStr = 'arrow_drop_down';
        this.create_product_tags = [];
        this.stockExcelExportButton = false;
        this.noStock = false;
        this.inStock = false;

        this.stocktakingBarcode = null;
        this.stocktakingShelfCode = null;
        this.stocktakings = [];
        this.stocktakingDetail = {};
        this.stocktakingId = false;

        this.shelfChangeBarcode = null;
        this.fromShelfCode = null;
        this.toShelfCode = null;
        this.fromShelfId = null;
        this.toShelfId = null;
        this.shelfChangeQuantity = null;

        this.tinymceOptions = {
            height: 350,
            plugins:
              "print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools contextmenu colorpicker textpattern code",
            toolbar:
              "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat",
        };


        this.getActionType();

        this.getProducts(1);

        /* events */
        var parent = this;
        this.$scope.$on('selectProductEvent', function (event, selectProduct){
            let tab = parent.$location.search().tab
            if(tab===3){
                parent.editProduct(selectProduct.id)
            }else {
                parent.showProduct(selectProduct.id);
            }
            //parent.addOfferItem(selectProduct.id);
        });
        this.$scope.$on('updateSelectProductEvent', function (event, product_id){
            parent.editProduct(product_id)
        });
        this.$scope.$on('showProduct', function (event, product_id){
            if (product_id){
                parent.showProduct(product_id)
            }else {
                parent.selectedTabIndex = 0;
            }
        });
        this.$scope.$on('paginationEvent', function (event, paginationData){
            parent.getProducts(paginationData.current_page, parent.filterArr, paginationData.per_page)
        });

    }

    onSelectTab(obj) {
        /*
        if(this.$stateParams){
            obj.product_code = this.$stateParams.product_code;
            obj.product_id = this.$stateParams.product_id;
            obj.term = this.$stateParams.term;
            if (this.$stateParams.tab) {
                obj.tab = parseInt(this.$stateParams.tab);
            }
        }
        */
        this.$location.search(obj);
        this.video = undefined;
        switch (obj.tab){
            case 0:
                this.video = 'stockList';
                break;
            case 1:
                this.video = 'stock-management'
                this.recipeItems = [];
                break;
            case 2:
                if(this.$stateParams && this.$stateParams.product_code){
                    this.product_code = this.$stateParams.product_code;
                    this.showProduct(this.$stateParams.product_id);
                    this.filterAnalytics();
                }
                this.$stateParams = null;
                break;
            case 3:
                if (this.product_id){
                    this.editProduct(this.product_id);
                }
                if (this.$stateParams && this.$stateParams.product_id) {
                    this.editProduct(this.$stateParams.product_id)
                }
                this.$stateParams = null;
                break;
            case 4:
                break;
            case 5:

                break;
        }
    }

    getActionType(safe = false){
        this.API.all("action-type")
          .customGET(null,{safe:safe?1:0})
          .then((response) => {
              this.actionTypes = response.data.types;
          });
    }
    changeCreateStockForm(wholesaleProduct) {
        if (wholesaleProduct == 1) {
            this.is_end_user_product = false;
            this.channelProductDetail.map(c => {
                if ('default' !== c.provider) c.checked = false
            })
        } else {
            this.is_end_user_product = true;
        }
    }

    loadBrands(query = "") {
        var self_ = this;

        this.API.all("brand/get")
            .post({
                query: query,
            })
            .then((response) => {
                self_.brands = response.data.brands;
            });

        return this.brands;
    }

    loadModels(query = "") {
        var self_ = this;

        this.API.all("model/get")
          .post({
              query: query,
          })
          .then((response) => {
              self_.models = response.data.models;
          });

        return this.models;
    }

    /*showHtmlActive(editor) {
          var customButton = document.querySelector('.ql-showHtml');
          var html = '<textarea id="htmlTextArea" style="position: absolute; top: 90px;height: 100%; width: 100%;margin: 0px;background: rgb(29, 29, 29);box-sizing: border-box;color: rgb(204, 204, 204);font-size: 15px;outline: none;padding: 20px;line-height: 24px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;bottom: 0;border: none; display:none;" ></textarea>';
          var editorObject = angular.element(document.querySelector('#create_editor'));

          editorObject.append(html);



          customButton.addEventListener('click', function() {
              var txtArea = angular.element(document.querySelector('#htmlTextArea'));
              if (txtArea.css( "display" ) === '') {
                  var html = txtArea.val();
                  console.log(html);
                  editor.pasteHTML(html);
              }

              txtArea.css("display", (txtArea.css("display") === 'none' ? '' : 'none'));
          });
      }

      textEditorChanged(editor, html, text, content, delta, oldDelta, source) {
          //var myEditor = document.querySelector('#create_editor');
          //var html = myEditor.children[0].innerHTML;
          var txtArea = angular.element(document.querySelector('#htmlTextArea'));
          txtArea.val(html);

      }*/

    setFilter() {
        this.filterArr = {
            searchFieldId: this.searchFieldId ?? null,
            searchValue: this.searchValue,
            searchTagValue: this.searchTagValue,

            categories: this.search_selected_categories,
            categoryIdStr: this.categoryIdStr ?? '',

            searchBrand: this.searchBrand ?? false,
            brandIdStr: this.brandIdStr ?? '',

            locationId: this.searchLocationId ?? null,

            //isActive: this.isActive ?? true,                    // aktif
            isActive: this.isActive,
            criticalStock: this.criticalStock,                  // kritik stok
            noStock: this.noStock,                              // eksi stok
            inStock: this.inStock,                              // artı stok
            isVirtualStock: this.isVirtualStock,                // sanal stok
            isBom: this.isBom ?? false,                         // paket ürün
            hasSubProducts: this.hasSubProducts ?? false,       // varyasyonlu ürün
            isRecipeProduct: this.isRecipeProduct ?? false,     // reçeteli ürün
            isProcurement: this.isProcurement,                  // tedarik sürecinde

            criticalStockState: this.criticalStockState ?? '',  // kritik stok seviyesi radioları


            searchCategoryTagValue: this.selectedCategoryTag ?? {},

            column: this.column,
            sortMode: this.sortMode,

            per_page: this.per_page,
            page: this.page,
            out: 'json',
        }

        if (this.searchBrand != false) {
            let parent = this;
            angular.forEach(this.searchBrand, function (brandValue, brandKey) {
                parent.brandIdStr += "," + brandValue.id;
            });
            this.filterArr['brandIdStr'] = this.brandIdStr;
        }

        if (this.search_selected_categories != false) {
            let parent = this;
            angular.forEach(
              this.search_selected_categories, function (categoryValue, categoryKey) {
                  parent.categoryIdStr += "," + categoryValue.id;
              }
            );
            this.filterArr['categoryIdStr'] = this.categoryIdStr;
        }
    }

    getProducts(page) {
        let parent = this;
        this.page = page;
        this.setFilter();

        this.API.all("products")
            .customGET("list", this.filterArr)
            .then((response) => {
                if (response.response.status === 204){
                    this.ToastService.show(this.$translate.instant('SEARCHED_VALUE_NOT_FOUND'))
                    return false;
                }
                parent.products = response.data.products;
                parent.total = response.data.products.total;
                parent.page = response.data.products.current_page;
                parent.per_page = response.data.products.per_page;
            })
            .then((response) => {

            });
    }

    getProducts111111(pageNumber = this.page, filterArr = this.filterArr, per_page = this.per_page) {
        var parent = this;

        this.per_page = per_page;
        this.page = pageNumber;
        this.brandIdStr = "";
        this.categoryIdStr = "";

        if (this.searchBrand != false) {
            var self_ = this;
            angular.forEach(this.searchBrand, function (brandValue, brandKey) {
                self_.brandIdStr += "," + brandValue.id;
            });
            this.filterArr['brandIdStr'] = this.brandIdStr;
        }

        if (this.search_selected_categories != false) {
            var self_ = this;
            angular.forEach(
              this.search_selected_categories,
              function (categoryValue, categoryKey) {
                  self_.categoryIdStr += "," + categoryValue.id;
              }
            );
            this.filterArr['categoryIdStr'] = this.categoryIdStr;
        }


        const customGet = {
        };

        var reqObject = angular.extend(customGet, filterArr);

        this.API.all("products")
          .customGET("list", reqObject)
          .then((response) => {
              if (response.response.status === 204){
                  this.ToastService.show(this.$translate.instant('SEARCHED_VALUE_NOT_FOUND'))
                  return false;
              }
              parent.paginationDataGenerator(parent, response);
          })
          .then((response) => {
              this.preferredProductCode = this.$rootScope.companyInfo['preferred-product-code'];
              angular.forEach(parent.products.data, function (item, key) {
                  //if (!item) return;
                  //if (!parent.nextImageIndexProduct[item.id]) return;
                  parent.nextImageIndexProduct[item.id] = 0;
              });
          });
    }

    getAnalytics(pageNumber = this.page, filterArr = this.filterArr, per_page = this.per_page) {
        var parent = this;

        //this.per_page = per_page;
        let customGet = {
            per_page: per_page,
            page: pageNumber,
            token: this.$auth.getToken(),
            id: this.product_id,
        };

        var reqObject = angular.extend(customGet, filterArr);

        reqObject.per_page = per_page;

        this.API.all("analytics/list")
            .post(reqObject)
            .then((response) => {
                parent.paginationAnalyticsDataGenerator(parent, response);
            });
    }

    productClone(productId) {
        this.productId = productId;
        let cloneProduct = this.products.data.filter(product => product.id === this.productId)[0];
        let parent = this;
        this.$mdBottomSheet.show({
            templateUrl: './views/angular/app/components/clone-stock/clone-stock.component.html',
            controller: "CloneStockController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                source_product_id: cloneProduct.id,
                ts_product_code: cloneProduct.ts_product_code,
                ts_product_name: cloneProduct.ts_product_name,
                ts_supplier_product_code: cloneProduct.ts_supplier_product_code,
                ts_barcode: cloneProduct.ts_barcode,
            }
        }).then(response => {
            /*
            if (response?.data?.productId) {
                this.selectedProduct = {id:response.data.productId}
                parent.selectProduct()
            }*/
        })

    }

    paginationDataGenerator(parent, response) {
        parent.products = response.data.products;
        parent.total = response.data.products.total;
        parent.current_page = response.data.products.current_page;
        parent.per_page = response.data.products.per_page;
        parent.last_page = response.data.products.last_page;
        parent.prev_page_url = response.data.products.prev_page_url;
        parent.next_page_url = response.data.products.next_page_url;

        parent.productsPagesArray = response.data.productsPagesArray;

        parent.prev_index = response.data.prevIndex;
        parent.next_index = response.data.nextIndex;
    }

    createStock() {
        let channelInfo = this.channelProductDetail.filter(f => f.checked && f.isActive).map(f => {
            const {
                channelId,
                vat,
                currency,
                currency_id,
                sellingPrice,
                ts_product_name,
                detail,
                seo_title,
                seo_link,
                seo_description
            } = f;
            return {
                channelId,
                vat,
                currency,
                currency_id,
                sellingPrice,
                ts_product_name,
                detail,
                seo_title,
                seo_link,
                seo_description
            };
        })

        let reqObject = {
            selected_categories: this.create_selected_categories,
            ts_supplier_product_code: this.create_ts_supplier_product_code,
            ts_product_code: this.create_ts_product_code,
            ts_supplier: this.create_ts_supplier,
            quantity: this.create_quantity,
            ts_barcode: this.create_ts_barcode,
            additional1: this.create_additional1,
            additional2: this.create_additional2,
            additional3: this.create_additional3,
            width: this.create_width,
            depth: this.create_depth,
            stock_unit_id: this.create_stock_unit_id,
            member_min_order: this.create_member_min_order,
            is_active: this.is_active,
            is_new_product: this.is_new_product,
            brand_id: this.create_brand_id,
            ts_product_name: this.create_ts_product_name,
            short_description: this.create_short_description,
            currency_id: this.create_currency_id,
            sellingPrice: this.create_sellingPrice,
            selling_price_with_vat: this.create_selling_price_with_vat,
            height: this.create_height,
            weight: this.create_weight,
            list_no: this.create_list_no,
            member_max_order: this.create_member_max_order,
            critic_stock_quantity: this.create_critic_stock_quantity,
            vat: this.create_vat,
            tags: this.create_product_tags,
            virtual_stock_quantity: this.create_virtual_stock_quantity,
            package_products: [],
            variations: this.variotionsProduct,
            wholesale_product: this.wholesale_product,
            product_tracking_mode: this.create_product_tracking_mode,
            channelInfo: channelInfo,
            detail: this.create_detail,
            seo_link: this.create_seo_link,
            seo_description: this.create_seo_description,
            seo_title: this.create_seo_title,
            image_path: this.create_image_path
        };

        if (this.createFormPackageProducts) {
            let packageProductError = []
            this.createFormPackageProducts.map(packageProduct => {
                if (packageProduct?.product[0].sub_products?.length > 0 && !packageProduct?.product[0]?.subProductId) {
                    packageProductError.push(packageProduct.product[0]?.ts_product_name)
                }
                reqObject['package_products'].push({
                    productId: packageProduct.product[0].id,
                    subProductId: packageProduct.product[0].subProductId,
                    quantity: packageProduct.qty
                })
            })

            if (packageProductError.length > 0) {
                this.ToastService.showHtml('warn', packageProductError.join(', ') + this.$translate.instant('CHOOSE_VARIANT_PACKAGE_PRODUCT'))
                return false;
            }
        }

        if (this.selectedCategoryTag) {
            reqObject.tags = [...reqObject.tags, ...Object.values(this.selectedCategoryTag).filter(item => item !== null)];
        }

        if (this.recipeItems.length > 0) {
            reqObject.recipeItems = this.recipeItems.map(r => {
                return {
                    productId: r.productId,
                    quantity: r.quantity,
                }
            })
        }

        var parent = this;

        this.API.all("stock/save")
            .post(reqObject)
            .then((response) => {
                if (response.errors === false) {
                    parent.ToastService.success(this.$translate.instant('STOCK_CARD_CREATED'));
                    this.clearCreateForm();
                }
            });
    }

    getCategoryTagCode() {
        let code = '';
        if (this.selectedCategoryTag && typeof vm.selectedCategoryTag === 'object') {
            let keys = Object.keys(this.selectedCategoryTag).sort((a, b) => {
                let aIndex = parseInt(a.split('.')[0]);
                let bIndex = parseInt(b.split('.')[0]);
                return aIndex - bIndex;
            });

            for (let i of keys) {
                code += this.selectedCategoryTag[i].name.split('-')[0];
            }
        }
        this.categoryTagCode = code; // Sonuç bir modele atanır
    }




    clearCreateForm() {
        this.create_selected_categories = [];
        this.create_brand_id = [];
        this.createFormPackageProducts =[];
        this.variotionsProduct = [];
        this.create_ts_supplier_product_code = this.create_ts_product_code = this.create_ts_supplier = this.create_quantity = null;
        this.create_seo_link = this.create_seo_description = this.create_ts_barcode = this.create_additional1 = this.create_additional2 = null;
        this.create_additional3 = this.create_width = this.create_depth = this.create_stock_unit_id = this.create_member_min_order = null;
        this.create_product_tracking_mode = 0;
        this.is_active = 1;
        this.is_new_product = this.create_ts_product_name = this.create_short_description = this.create_currency_id = null;
        this.create_sellingPrice = this.create_seo_title = this.create_detail = this.create_height = this.create_weight = this.create_list_no = null;
        this.create_member_max_order = this.create_critic_stock_quantity = this.create_currency_id = null;
        this.create_vat = this.create_product_tags = this.create_virtual_stock_quantity = this.selectedCategoryTag = null;
        this.create_image_path = null;
    }

    edit(actionId) {
        this.selectedDetailActionId = actionId;
        let reqObject = {
            id: actionId,
        };

        var parent = this;
        this.disabled[actionId] = true;

        if (0 === this.branches.length){
            this.branchesList()
        }
    }
    branchesList(){
        this.API.all("settings/branch").doGET()
            .then((response) => {
                if (204 === response.response.status) return;
                this.branches = response.data.list

                this.branch = response.data.list.filter(f => f.default)[0].id
                this.editBranch = this.branch
            });

    }



    filterAnalytics() {
        var filter = {
            startDate: this.filterStartDate,
            endDate: this.filterEndDate,
            actionTypeId: this.filterActionTypeId,
            transactionId: this.filterTransactionId,
            channelId: this.filterChannelId,
            subProductId: this.filterSubProductId,
            id: this.product_id,
            per_page: this.per_page,
            pageNumber: 1,
            locationId: this.filterLocationId,
            issueType: this.issueType,
        };

        var parent = this;
        this.analyticsFilterArr = filter;

        this.API.all("analytics/filter")
            .post(filter)
            .then((response) => {
                parent.paginationAnalyticsDataGenerator(parent, response);
            });
    }

    paginationAnalyticsDataGenerator(parent, response) {
        parent.salesAnalyticsProductScreen.analytics = response.data.analytics;
        parent.salesAnalyticsProductScreen.analyticsPagesArray =
            response.data.analyticsPagesArray;
        parent.salesAnalyticsProductScreen.prev_index = response.data.prevIndex;
        parent.salesAnalyticsProductScreen.next_index = response.data.nextIndex;
        parent.salesAnalyticsProductScreen.current_page = response.data.analytics.current_page;
        parent.salesAnalyticsProductScreen.total_movement_qty = response.data.total_movement_qty;
    }

    clearForm() {
        this.searchFieldId = this.searchValue = this.searchLocationId = null;
        this.searchBrand = [];

        this.search_selected_categories = [];
        this.categoryIdStr = null;

        this.searchTagValue = '';
        this.selectedCategoryTag = {};
        this.isActive = true;
        this.criticalStock = false;
        this.noStock = false;
        this.inStock = false;
        this.isVirtualStock = false;
        this.isBom = false;
        this.hasSubProducts = false;
        this.isRecipeProduct = false;
        this.isProcurement = false;

        this.criticalStockState = '';

        this.column = 'updated_at';
        this.sortMode = 'desc';
        this.sortModeStr = 'arrow_drop_down';

        this.getProducts(1);
    }

    deleteProduct(product_id) {
        this.DialogService.confirm(
            this.$translate.instant('CONFIRM_MESSAGE'),
            this.$translate.instant('WANT_TO_DELETE_PRODUCT')
        ).then(
            () => {
                var reqData = {
                    product_id: product_id,
                    per_page: this.per_page,
                    page: this.pageNumber,
                    searchFieldId: this.searchFieldId,
                    searchValue: this.searchValue,
                };

                var parent = this;

                this.disabled[product_id] = true;

                this.API.all("stock/delete")
                    .post(reqData)
                    .then((response) => {
                        parent.paginationDataGenerator(parent, response);
                        parent.disabled[product_id] = false;
                    })
                    .catch(function (response) {
                        parent.disabled[product_id] = false;
                    });
            },
            () => {
            }
        );
    }

    onSwipe(event, target, id, direction){
        this.products.data.map((item)=>{
            if (item.id === id) {
                item.swipe = !item.swipe;
            }
        });
    }

    showProduct(product_id) {
        this.product_id = product_id;
        this.recipeProducts = null;
        this.includedRecipe = null;
        var parent = this;
        this.disabled[product_id] = true;
        this.API.all("stock/getById")
            .post({
                id: product_id,
                productType:'product'
            })
            .then((response) => {
                this.selectedProduct = response.data.product;
                this.product_id = response.data.product.id
                this.product_code = this.selectedProduct.ts_product_code;
                this.supplier_product_code = this.selectedProduct.ts_supplier_product_code;
                this.ts_product_name = this.selectedProduct.ts_product_name;
                this.barcode = this.selectedProduct.ts_barcode;
                if (response.data.product.has_recipe) {
                    this.recipeProducts = response.data?.product?.recipe?.recipes?.filter(recipe => recipe.active === 1);
                }
                if(response.data.product.includedRecipe.length > 0){
                    this.includedRecipe = response.data.product.includedRecipe;
                }
                this.showAllLotByProductId(this.product_id);
                this.subProductsOnLocations(this.product_id);
                this.getProductAnalyticsSummary(this.product_id);
                this.getProductAnalyticsAssignList(this.product_id);
                this.selectedTabIndex = 2;
            });

    }

    showBottomSheet(isShowCamera) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                isShowCamera: isShowCamera
            }
        })
    }
    showProductBottomSheet(productId) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                id: productId
            }
        })
    }

    editProduct(product_id) {
        this.editProducts = true;
        this.recipeProducts = null;
        this.includedRecipe = null;
        this.product_id = product_id;
        if (this.products.data) {
            this.selectedProduct = this.products.data.filter(product => product.id === this.product_id)[0];
            this.product_code = this.selectedProduct?.ts_product_code ?? null;
            this.supplier_product_code = this.selectedProduct?.ts_supplier_product_code ?? null;
            this.barcode = this.selectedProduct?.ts_barcode ?? null;
        }

        var parent = this;
        this.disabled[product_id] = true;

        this.API.all("stock/getById")
            .post({id: product_id})
            .then((response) => {
                var key = this.findElement(this.brands, response.data.product.brand_id);
                if (response.data.productChannelInfo !== undefined) {
                    this.channelProductDetailEdit = this.channels.map(c => {
                        let channelInfo = response.data.productChannelInfo.filter(p => p.channel_id === c.id)[0]
                        return {
                            checked: !!channelInfo,
                            provider: c.provider,
                            name: c.name,
                            channelId: c.id,
                            isActive: c.is_active,
                            vat: channelInfo?.vat,
                            currency_id: channelInfo?.currency_id,
                            currency: channelInfo?.currency ? channelInfo?.currency : channelInfo?.currency_id,
                            sellingPrice: channelInfo?.selling_price,
                            sellingPrice_vat_included: channelInfo?.selling_price_vat_included,
                            sellingPrice_vat_included_nodiscount: channelInfo?.selling_price_vat_included_nodiscount,
                            discounted_sellingPrice: channelInfo?.discounted_sellingPrice,
                            discounted_sellingPrice_vat_included: channelInfo?.discounted_sellingPrice_vat_included,
                            ts_product_name: channelInfo?.ts_product_name,
                            detail: channelInfo?.detail,
                            seo_title: channelInfo?.seo_title,
                            seo_link: channelInfo?.seo_link,
                            full_seo_link: channelInfo?.full_seo_link,
                            full_panel_link: channelInfo?.full_panel_link,
                            seo_description: channelInfo?.seo_description,
                            ts_product_id: channelInfo?.ts_product_id,
                            transfer_message: channelInfo?.transfer_message,
                        }
                    });
                } else {
                    this.channelProductDetailEdit = null;
                }
                parent.editProductScreen =
                    {
                        title: response.data.product.ts_product_name,
                        productObject: response.data.product,
                        productChannelInfoObj: response.data.productChannelInfo,
                        is_active: parseInt(response.data.product.is_active),
                        is_new_product: parseInt(response.data.product.is_new_product),
                        brand_selected_key: key,
                        channelProductDetailEdit: this.channelProductDetailEdit,
                        tags: response.data.product.tags
                    };
                this.selectedProduct = response.data.product;
                if (response.data.product.has_recipe) {
                    this.recipeProducts = response.data.product.recipe.recipes.filter(recipe => recipe.active === 1);
                }
                if(response.data.product.includedRecipe.length > 0){
                    this.includedRecipe = response.data.product.includedRecipe;
                }
                this.product_code = this.selectedProduct?.ts_product_code ?? null;
                this.supplier_product_code = this.selectedProduct?.ts_supplier_product_code ?? null;
                this.barcode = this.selectedProduct?.ts_barcode ?? null;

                this.disabled[product_id] = false;
                this.edit_brand_id = this.brands.filter(f => this.selectedProduct.brand_id === f.id);
                this.edit_model_id = this.models.filter(f => this.selectedProduct.model_id === f.id);

                var self_ = this;
                this.edit_selected_categories = [];
                this.editFormPackageProducts = response.data.bomArray;
                this.editVariotionsProduct = response.data.subProductArray;
                angular.forEach(
                    response.data.selected_categories,
                    function (category, key) {
                        key = self_.findElement(self_.categories, category.id);
                        if (key !== ""){
                            self_.edit_selected_categories.push(self_.categories[key]);
                        }
                    }
                );
                parent.selectedTabIndex = 3;

                //this.state.params.tab = wizardItem.link.params.tab;
                //this.$location.search({tab: 3});
                this.clearImage('image');
                this.defaultProductImage = false;
                this.defaultProductImageFolder = false;
                this.getProductAnalyticsAssignList(product_id);
            });
    }

    updateStock(productId) {

        let channelInfo = this.channelProductDetailEdit.filter(f => f.checked).map(f => {
            const {
                channelId,
                vat,
                currency,
                currency_id,
                sellingPrice,
                ts_product_name,
                detail,
                seo_title,
                seo_link,
                full_seo_link,
                seo_description
            } = f;
            return {
                channelId,
                vat,
                currency,
                currency_id,
                sellingPrice,
                ts_product_name,
                detail,
                seo_title,
                seo_link,
                full_seo_link,
                seo_description
            };
        })

        let reqObject = {
            product_id: productId,
            selected_categories: this.edit_selected_categories,
            ts_supplier_product_code: this.editProductScreen.productObject.ts_supplier_product_code,
            ts_product_code: this.editProductScreen.productObject.ts_product_code,
            ts_supplier: this.editProductScreen.productObject.ts_supplier,
            quantity: this.editProductScreen.productObject.quantity,
            ts_barcode: this.editProductScreen.productObject.ts_barcode,
            additional1: this.editProductScreen.productObject.additional1,
            additional2: this.editProductScreen.productObject.additional2,
            additional3: this.editProductScreen.productObject.additional3,
            width: this.editProductScreen.productObject.width,
            depth: this.editProductScreen.productObject.depth,
            stock_unit_id: this.editProductScreen.productObject.stock_unit_id,
            member_min_order: this.editProductScreen.productObject.member_min_order,
            is_active: this.editProductScreen.is_active,
            is_new_product: this.editProductScreen.is_new_product,
            brand_id: this.edit_brand_id,
            ts_product_name: this.editProductScreen.productObject.ts_product_name,
            short_description: this.editProductScreen.productObject.short_description,
            currency_id: JSON.stringify(this.edit_currency_id),
            sellingPrice: JSON.stringify(this.edit_sellingPrice),
            selling_price_with_vat: this.editProductScreen.selling_price_with_vat,
            height: this.editProductScreen.productObject.height,
            weight: this.editProductScreen.productObject.weight,
            list_no: this.editProductScreen.productObject.list_no,
            member_max_order: this.editProductScreen.productObject.member_max_order,
            critic_stock_quantity: this.editProductScreen.productObject.critic_stock_quantity,
            virtual_stock_quantity: this.editProductScreen.productObject.virtual_stock_quantity,
            vat: JSON.stringify(this.edit_vat),
            tags: this.editProductScreen.tags,
            package_products: [],
            product_tracking_mode: this.editProductScreen.productObject.product_tracking_mode,
            variations: this.editVariotionsProduct,
            channelInfo: channelInfo,
            detail: this.editProductScreen.productObject.detail,
            seo_link: this.editProductScreen.productObject.seo_link,
            seo_description: this.editProductScreen.productObject.seo_description,
            seo_title: this.editProductScreen.productObject.seo_title,
            image_path: this.editProductScreen.image_path
        };

        if (this.editFormPackageProducts) {
            let packageProductError = []

            this.editFormPackageProducts.map(packageProduct => {
                if (packageProduct.new) {
                    if (packageProduct.product[0].sub_products.length > 0 && !packageProduct.product[0].subProductId) {
                        packageProductError.push(packageProduct.product[0].ts_product_name)
                    }
                    reqObject['package_products'].push({
                        productId: packageProduct.product[0].id,
                        subProductId: packageProduct.product[0].subProductId,
                        quantity: packageProduct.quantity
                    })
                } else {
                    reqObject['package_products'].push({
                        productId: packageProduct.productId,
                        subProductId: packageProduct.subProductId,
                        quantity: packageProduct.quantity
                    })
                }
            })

            if (packageProductError.length > 0) {
                this.ToastService.showHtml('warn', packageProductError.join(', ') + this.$translate.instant('CHOOSE_VARIANT_PACKAGE_PRODUCT'))
                return false;
            }
        }

        var parent = this;

        this.API.all("stock/update")
            .post(reqObject)
            .then(() => {
                parent.ToastService.success(this.$translate.instant('STOCK_CARD_UPDATED'));
                parent.showProduct(productId);
            });
    }

    loadImage(file,target) {
        if (!file) return false
        if (file[0].size >3145728){
            this.ToastService.error(this.$translate.instant('FILE_SIZE_BE_UP_3MB'))
            return ;
        }
        let parent = this
        parent.branch[target] =  parent[target] =null;
        const img = new Promise((resolve, reject)=>{
            parent.branch[target] = file;
            const reader = new FileReader();
            reader.readAsDataURL(parent.branch[target][0]);
            reader.onload = () => resolve(reader.result);
            reader.onerror = ()=> reject(reader.error)
        })
        Promise.all([img]).then(base64=>{
            parent[target] = base64
        }).catch(error=>{
            console.log('img-error->',error)
        })
    }

    uploadImage(productId){
        if (this.branch.image[0].size >500000){
            this.ToastService.error(this.$translate.instant('FILE_SIZE_BE_UP_500KB'))
            return ;
        }
        let parent = this;
        let data = new FormData();

        data.append('product_id', productId)
        if (this.branch.image) {
            data.append('image', this.branch.image[0])
        }
        data.append('type', 'product-image')

        let url = this.$window.__env.apiUrl + "/api/upload/file";
        let xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("Authorization", "Bearer " + this.$window.localStorage.satellizer_token,);

        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                let response = JSON.parse(xhr.responseText);
                if (xhr.status === 200) {
                    parent.ToastService.success(this.$translate.instant('IMAGE_UPLOAD_SUCC'));
                    parent.editProduct(productId);
                    parent.clearImage('image');
                } else {
                    parent.ToastService.error(response.errors.message[0]);
                    parent.clearImage('image');
                }
            }
        };
        xhr.send(data);
    }
    clearImage(key){
        this[key] = this.branch[key] = null ;
        this.branch['delete'+key] = 1;
    }

    triggerFileSelect () {
        var fileInput = document.getElementById('file-image-lite');
        fileInput.click();

        var parent  = this;
        fileInput.onchange = function () {
            parent.uploadImageLite();  // Call the upload function
        };
    }

    removeImage() {
        var parent  = this;
        parent.editProductScreen.productObject.images[0].image_path = '';
    }
    uploadImageLite() {
        var fileInput = document.getElementById('file-image-lite');
        var file = fileInput.files[0];

        if (!file) {
            alert('Please select an image file.');
            return;
        }

        if (!this.selectedProduct.id || isNaN(this.selectedProduct.id)) {
            alert('Please enter a valid product ID.');
            return;
        }

        var formData = new FormData();
        formData.append('image', file);
        formData.append('product_id', this.selectedProduct.id);
        formData.append('type','product-image');

        this.isLoading = true;
        var parent  = this;

        this.API.all("upload/file")
          .customPOST(formData, '', {}, {'Content-Type': undefined})
          .then(function(response) {
              parent.ToastService.success(response.data.message);
              if (!parent.editProductScreen.productObject.images) {
                  parent.editProductScreen.productObject.images = [];
              }
              if (!parent.editProductScreen.productObject.images[0]) {
                  parent.editProductScreen.productObject.images[0] = {};
              }
              parent.editProductScreen.productObject.images[0].image_path = response.data.image_path;
              parent.isLoading = false;
          }).catch(function(error) {
            //parent.ToastService.success(error.data.message);
            console.error(error);
            parent.isLoading = false;
        });
    };

    findElement(array, id) {
        var returnKey = 0;
        angular.forEach(array, function (value, key) {
            if (id == value.id) {
                returnKey = parseInt(key);
            }
        });

        return returnKey;
    }

    openMenu($mdMenu, ev) {
        this.originatorEv = ev;
        $mdMenu.open(ev);
    };

    removeProductTab() {
        this.editProducts = false;
        this.editProductScreen = {};
        this.selectedTabIndex = 0;
    }

    removeStocktakingTab() {
        this.stocktakingDetail = {};
        this.selectedTabIndex = 5;
    }

    changeSortMode() {
        this.sortMode = this.sortMode === 'asc' ? 'desc': 'asc';
        if (this.sortMode === 'asc') {
            this.sortModeStr = "arrow_drop_up";
        }
        if (this.sortMode === 'desc') {
            this.sortModeStr = "arrow_drop_down";
        }
        this.getProducts(1);
    }

    loadProductTags($vquery) {
        let customGet = {
            query: $vquery,
            token: this.$auth.getToken(),
        };

        if ($vquery != undefined) {
            var self_ = this;

            this.$http
                .post(window.__env.apiUrl + "/api/products/tags", customGet, {cache: false, async: true})
                .then(function (response) {
                    self_.tags = response.data.data.tags;
                })
                .finally(function () {
                    self_.tags = self_.tags;
                });

            return this.tags;
        }
    }

    addPackageProducts(mode = "create") {
        if (mode == "create") {
            this.createFormPackageProducts.push({
                product: [],
                qty: 1,
            });
        } else {
            this.editFormPackageProducts.push({
                product: [],
                quantity: 1,
                new: true
            });
        }
    }

    addRecipeProducts(product) {
        if (!product?.id){
            this.ToastService.showHtml('warn',this.$translate.instant('FAULTY_PRODUCT'))
            return false;
        }
        let existsItem = this.recipeItems.findIndex(r => r.productId === product.id);


        if (!product?.recipeId && existsItem !== -1){
            ++this.recipeItems[existsItem].newQuantity;
            this.ToastService.showHtml('success',this.$translate.instant('PRODUCT_INCREASED'))
            return true
        }

        this.recipeItems.push({
            recipeId    : product?.recipeId,
            productId   : product.id,
            productName : product?.ts_product_name,
            barcode     : product?.ts_barcode,
            productCode : product?.ts_product_code,
            quantity    : product?.quantity ? product?.quantity : 1,
            newQuantity : product?.newQuantity ? product?.newQuantity : 1,
            images      : product?.images
        });
        if (!product?.recipeId){
            this.ToastService.showHtml('success',this.$translate.instant('PRODUCT_ADDED'))
        }
    }

    removeBOM(index, mode = "create") {
        if (mode == "create") {
            this.createFormPackageProducts.splice(index, 1);
        } else {
            this.editFormPackageProducts.splice(index, 1);
        }
    }

    getMatches(query = "") {
        var self_ = this;
        var productProm = this.$q.defer();
        this.API.all("stock/search")
            .post({
                stockName: query,
            })
            .then((response) => {
                productProm.resolve((self_.searchResults = response.data.products));
            });

        return productProm.promise;
    }

    getProductAnalyticsSummary(product_id) {
        this.product_id = product_id;
        var parent = this;
        this.disabled[product_id] = true;
        this.API.all("stock/analytics-summary/"+product_id)
            .customGET()
            .then((response) => {
                parent.productSalesAnalyticsSummaryObject = response.data.analyticsSummary;
                this.disabled[product_id] = false;
            });
    }
    getProductAnalyticsAssignList(product_id) {
        this.product_id = product_id;
        var parent = this;
        this.disabled[product_id] = true;
        this.API.all("stock/analytics-not-assign-list/"+product_id)
            .customGET()
            .then((response) => {
                parent.productAnalyticsNotAssignListObject = response.data.analyticsNotAssignList.data;
                parent.originalProductAnalyticsList = [...response.data.analyticsNotAssignList.data];
                this.total_movement_qty = response.data.analyticsNotAssignList.total_movement_qty;
                this.list_length = response.data.analyticsNotAssignList.list_length;
                this.disabled[product_id] = false;
                this.currentPage = 0;
                this.hasMoreData = true;
                this.updatePagedData();
            });
    }

    updatePagedData(){
        let start = this.currentPage * this.itemsPerPage;
        let end = start + this.itemsPerPage;
        this.pagedData = this.productAnalyticsNotAssignListObject.slice(start, end);
        if (end >= this.productAnalyticsNotAssignListObject.length) {
            this.hasMoreData = false;
        }
    }
    showNext() {
        this.currentPage++;
        this.updatePagedData();
    }
    showPrevious () {
        if (this.currentPage > 0) {
            this.hasMoreData = true;
            this.currentPage--;
            this.updatePagedData();
        }
    }

    showOrderDetail(id) {
        let options = {
            controller: 'InvoicesDetailController',
            controllerAs: 'vm',
            locals: {
                orderId: id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getOrderDetail(controller.orderId)
            },
        }
        this.DialogService.fromTemplateV2(
          './views/angular/app/components/invoices-detail/invoices-detail.component.html',
          options
        )
    }

    showActionDetail(action_id) {
        this.selectedDetailActionId = action_id;
        let options = {
            controller: 'ActionDetailController',
            controllerAs: 'vm',
            locals: {
                id: action_id
            },
            bindToController: true,
            onShowing: function (scope, element, options, controller) {
                controller.getDetail(controller.id)
            },
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/order-lists/partials/action-detail.component.html',
            options
        )
    }

    subProductsOnLocations(product_id) {
        this.product_id = product_id;
        this.disabled[this.product_id] = true;
        var parent = this;

        this.API.all("stock/sub-products/warehouses")
          .post({product_id: this.product_id})
          .then((response) => {
              parent.subProductScreen = response.data.subProductOnLocations;
              this.disabled[this.product_id] = false;
              ////////////this.selectedTabIndex = 2;
          });
    }

    clearFilterAnalytics() {
        this.filterStartDate = this.filterEndDate = this.filterActionTypeId = null;
        this.filterTransactionId = this.filterChannelId = null;
        this.filterLocationId = this.filterSubProductId = null;

        let customGet = {
            per_page: this.per_page,
            page: 1,
            pageNumber: 1,
            token: this.$auth.getToken(),
            id: this.product_id,
        };

        var parent = this;

        this.API.all("analytics/list")
          .post(customGet)
          .then((response) => {
              parent.paginationAnalyticsDataGenerator(parent, response);
          });

        this.analyticsFilterArr = {};
    }

    removeSalesAnalyticsProductTab() {
        this.salesAnalyticsProductScreen = {};
    }

    toggleAccordion(actionId, expanded) {
        this.accordion[actionId] = expanded;
        this.expanded[actionId] = expanded;
    }

    stockExcelExportQueue() {
        var parent = this;
        this.stockExcelExportButton = true;
        let filter = this.filterArr;
        filter['token'] = window.localStorage.satellizer_token

        this.API.all("products")
            .customGET("list", this.filterArr, {
                'x-api-output': 'excel',
            })
            .then((response) => {
                parent.ToastService.success(response.data.message);
                return false;
            })

    }
    filterNotAssignedStockMovement(value){
        this.allList = true;
        this.notAssignedToList = true;
        if (value === 'awaited') {
            this.productAnalyticsNotAssignListObject = this.originalProductAnalyticsList.filter(item => item.action_mode !== '-');
        } else if (value === 'reserved') {
            this.productAnalyticsNotAssignListObject = this.originalProductAnalyticsList.filter(item => item.action_mode === '-');
        } else {
            this.productAnalyticsNotAssignListObject = [...this.originalProductAnalyticsList];
        }
        this.currentPage = 0;
        this.hasMoreData = true;

        this.list_length = this.productAnalyticsNotAssignListObject.length;

        this.total_movement_qty = this.productAnalyticsNotAssignListObject.reduce((total, item) => {
            return total + (item.quantity || 0);
        }, 0);

        this.updatePagedData();
    }

    showListDetail(listId) {
        let options = {
            controller: "IssuesDetailController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                listId: listId
            },
            onShowing: function (scope, element, options, controller) {
                controller.getList()
            },
        }
        this.DialogService.fromTemplateV2("./views/angular/app/components/issues/partials/issues-detail.html",options)
            .then((response)=>{
            })
    }

    printStockCartV2(productId) {

        var reqData = {
            product_id: productId,
        };

        this.API.all("stock/print-barcode")
            .post(reqData)
            .then((response) => {

                const printWindow = window.open('', '_blank');
                printWindow.document.write(response.data.nativeFileName);
                printWindow.document.close();
                printWindow.onload = function() {
                    printWindow.print();
                    printWindow.close();
                };
            })
            .catch(function (error) {
                console.error( error);
            });
    }

    forceOneTag(tags) {
        if (tags !== null && tags !== undefined) {
            return tags.length === 0;
        } else {
            return true;
        }
    }

    createLotOrSNuForStock(productId) {
        var reqData = {
            lot_or_snu: this.createLotOrSNu,
            //product_id: JSON.stringify(this.createLotProductId),
            product_id: this.selectedProduct.id,
            qty: this.createLotQty,
            expired_date: this.createLotExpiredDate,
            per_page: this.lot_per_page,
        };

        var parent = this;

        this.API.all("stock/saveLotOrSnu")
            .post(reqData)
            .then((response) => {
                parent.ToastService.success(this.$translate.instant('SERIAL_LOT_RECORDED'));
                parent.lotResponseGenerator(parent, response);

                if (this.collapseLotsForProduct.length > 0) {
                    this.getLotForProducts(1, parent.lotsForProductPerPage);
                }

                this.clearCreateLotForm();
            });
    }

    lotResponseGenerator(parent, response) {
        parent.lotOrSNuData = response.data.data;
        parent.lot_total = response.data.total;
        parent.lot_current_page = response.data.current_page;
        parent.lot_per_page = response.data.per_page;
        parent.lot_last_page = response.data.last_page;
        parent.lot_prev_page_url = response.data.prev_page_url;
        parent.lot_next_page_url = response.data.next_page_url;

        parent.lotOrSNuPagesArray = response.dataPagesArray;

        parent.lotOrSNuDataPrevIndex = response.prevIndex;
        parent.lotOrSNuDataNextIndex = response.nextIndex;
    }

    getLotsForProducts(page_number, per_page) {
        let reqData = {
            per_page: per_page,
            page: page_number,
        };

        this.lot_per_page = per_page;
        var parent = this;

        this.API.all("stock/lot-snu")
            .post(reqData)
            .then((response) => {
                parent.lotResponseGenerator(parent, response);
            });
    }

    showAllLotByProductId(product_id) {
        this.lotAElDisabled.push(product_id);
        if (this.collapseLotsForProduct.indexOf(product_id) !== -1) {
            this.collapseLotsForProduct = [];
            this.lotAElDisabled = [];
            return false;
        }

        let reqData = {
            product_id: product_id,
        };

        var parent = this;
        this.collapseLotsForProduct = [];
        this.showLotProductId = product_id;
        this.API.all("stock/lot-snu/" + reqData.product_id)
            .post(reqData)
            .then((response) => {
                parent.lotsForProductResponseGenerator(parent, response);
                parent.collapseLotsForProduct.push(product_id);
                parent.lotAElDisabled = [];
            });
    }

    lotsForProductResponseGenerator(parent, response) {
        parent.lotsForProductData = response.data.lotsForProduct.data;
        parent.lotsForProductCurrentPage = response.data.lotsForProduct.current_page;
        parent.lotsForProductLastPage = response.data.lotsForProduct.last_page;
        parent.lotsForProductPerPage = response.data.lotsForProduct.per_page;
        parent.lotsForProductTotal = response.data.lotsForProduct.total;
        parent.lotsForProductPagesArray = response.data.lotsForProductPagesArray;

        parent.lotsForProductPrevPageUrl = response.data.lotsForProduct.prev_page_url;
        parent.lotsForProductNextPageUrl = response.data.lotsForProduct.next_page_url;

        parent.lotsForProductNextIndex = response.data.nextIndex;
        parent.lotsForProductPrevIndex = response.data.prevIndex;
    }

    getLotForProducts(page_number, per_page) {
        let reqData = {
            per_page: per_page,
            page: page_number,
            product_id: this.showLotProductId,
        };

        this.lot_per_page = per_page;
        var parent = this;

        this.API.all("stock/lot-snu/" + reqData.product_id)
            .post(reqData)
            .then((response) => {
                parent.lotsForProductResponseGenerator(parent, response);
            });
    }

    lotDelete(product_id) {
        let reqData = {
            productId: product_id,
        };

        var parent = this;

        this.API.all("delete-lot-for-product")
            .post(reqData)
            .then((response) => {
                parent.getLotForProducts(1, parent.lot_per_page);
            });
    }


    searchLot() {
        let reqData = {
            field_id: 1,
            value: this.searchLotFormValue,
            per_page: this.lot_per_page,
        };

        var parent = this;

        this.API.all("stock/lot-snu")
            .post(reqData)
            .then((response) => {
                parent.lotResponseGenerator(parent, response);
            });
    }

    searchLotInProduct() {
        let reqData = {
            field_id: this.searchLotFormFieldId,
            value: this.searchLotFormValue,
            per_page: this.lot_per_page,
        };

        var parent = this;

        this.API.all("stock/lot-snu")
            .post(reqData)
            .then((response) => {
                parent.lotResponseGenerator(parent, response);
            });
    }

    clearSearchLotForm() {
        this.searchLotFormFieldId = null;
        this.searchLotFormValue = null;
        this.getLotsForProducts(1, this.lot_per_page);
    }

    clearCreateLotForm() {
        this.createLotOrSNu = null;
        this.createLotProductId = null;
        this.createLotExpiredDate = null;
    }

    openLotOrSnuDialog(product_id, location_id) {
        let reqData = {
            product_id: product_id,
            location_id: location_id,
        };

        var options = {
            locals: {reqData: reqData},
            controller: "GetLotOrSeriForProductsByLocationIdController",
        };
        var lotDetailDialogPromise = this.DialogService.fromTemplate(
            "getLotOrSeriForProductsByLocationId",
            options
        );
        var parent = this;

        lotDetailDialogPromise.then(
            function () {
            },
            function () {
            }
        );
    }

    openIntroductionVideoModal(video) {
        openIntroductionVideo(video);
    }

    showChannelsDetail(product_id) {
        this.channelsDetail[product_id] = undefined === this.channelsDetail[product_id] ? true : undefined;
    }

    addVariationsRow() {
        this.variotionsProduct.push({
            property1: '',
            property2: '',
            subProductCode: '',
            subProductSupplierProductCode: '',
            subProductBarcode: '',
            subProductQuantity: '',
            subProductSellingPrice: '',
            subProductLot: 0,
        })
    }

    addEditVariationsRow() {
        this.editVariotionsProduct.push({
            property1: '',
            property2: '',
            subProductCode: '',
            subProductSupplierProductCode: '',
            subProductBarcode: '',
            subProductQuantity: '',
            subProductSellingPrice: '',
            subProductLot: 0,
            new: true,
        })
    }

    deleteVariationsRow(index) {
        this.variotionsProduct = this.variotionsProduct.filter((vp, i) => i !== index)
    }

    deleteEditVariationsRow(index) {
        this.editVariotionsProduct = this.editVariotionsProduct.filter((vp, i) => i !== index)
    }

    packageProductSelectedProductControl(selectedProduct) {
        selectedProduct.product[0] ? false : true;
    }

    stockUnitLabelChange() {
        this.stockUnitLabel = this.stockUnit.find(s => s.id === this.create_stock_unit_id)['name']
    }

    /* Burası üç fonksiyonla birlikte component olabilir template i ile birlikte  */
    prevImage(id, length) {
        let index = this.nextImageIndexProduct[id] - 1;
        this.changeImageIndex(id, index, length);
    }

    nextImage(id, length) {
        let index = this.nextImageIndexProduct[id] + 1;
        this.changeImageIndex(id, index, length);
    }

    changeImageIndex(id, index, length) {
        this.nextImageIndexProduct[id] = (index < 0) ? 0 :
            (index > length - 1) ? length - 1 : index;
    }

    productSync(productId) {
        this.API.all('/stock/product/sync')
            .customPUT({productIds: productId})
            .then((response) => {
                this.ToastService.showHtml('success', response.data.message)
            });
    }

    checkProduct(barcode) {
        this.API.all('stocktaking/product/' + barcode)
            .customGET(null, {
                locationId: this.locationId,
                shelfId: this.stocktakingShelfId,
                stockTakingId: this.stocktakingDetail.stocktakingId
            })
            .then((response) => {
                if (response.response.status === 204) {
                    this.ToastService.error(this.$translate.instant('NO_PRODUCT_WITH_BARCODE'));
                    return;
                }
                this.stocktakingProduct = response.data.list
            })
    }

    dialogLotSeri() {
        var options = {
            controller: "CreateLotSeriController",
            controllerAs: "vm",
            bindToController: true,
            clickOutsideToClose: true,
            locals: {
                product_id: this.product_id
            }
        }
        var lotseriDialog = this.DialogService.fromTemplateV2("./views/angular/app/components/stock-management/partials/create-lot-seri.html",options)
        lotseriDialog.then((response)=>{
            this.createLotOrSNu = response.createLotOrSNu;
            this.createLotQty = response.createLotQty;
            this.createLotExpiredDate = response.createLotExpiredDate;

            this.createLotOrSNuForStock(this.product_id);
        })
    }

    getAccountingQuickView(id) {
        let barcode = this.barcode
        let productCode = this.product_code;
        if (id == 'edit') {
            barcode = this.editProductScreen.productObject.ts_barcode;
            productCode = this.editProductScreen.productObject.ts_product_code;
        }else {
            barcode='';
            productCode='';
        }

        var options = {
            controller: 'StockManagementAccountingController',
            controllerAs: 'vm',
            //  scope             : this.$scope,
            locals: {
                productId: id,
                barcode: barcode,
                productCode: productCode
            },
            bindToController: true,
            multiple: true,
            onShowing: function (scope, element, options, controller) {
                controller.getFromAccounting()
            },
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
          './views/angular/app/components/stock-management/partials/stock-management.accounting.html',
          options
        );
        customerDialogPromise.catch(err => {
            console.log(err)
        })
    }

    imageUpload() {
        if (this.defaultProductImage){
            this.defaultProductImage = false;
            this.defaultProductImageFolder = false;
        } else {
            this.defaultProductImage = true;
            this.defaultProductImageFolder = false;
        }
    }
    imageUploadFolder() {
        if (this.defaultProductImageFolder){
            this.defaultProductImageFolder = false;
            this.defaultProductImage = false;
        } else {
            this.defaultProductImageFolder = true;
            this.defaultProductImage = false;
        }
    }


    productSetPassiveActive(productId,data){
        this.API.all("stock/"+productId)
            .customPUT(data)
            .then(() => {
                this.ToastService.success(this.$translate.instant('STOCK_CARD_UPDATED'));
            });
    }


    showItemRecipe(productName,recipeId){
        let parent = this;
        let d = this.DialogService;
        this.API.all('recipe/'+recipeId)
            .customGET()
            .then((response) => {
                let options = {
                    controllerAs      : 'vm',
                    bindToController  : true,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    onShowing         : function (scope, element, options, controller){
                        scope.productName = productName;
                        scope.recipe = response.data.list;
                        scope.items = response.data.list.items;
                        scope.DialogService = d;
                        scope.cancel = () => {
                            scope.DialogService.$mdDialog.hide()
                        }
                        scope.goRecipe = (productId, recipeId=null) => {
                            parent.$state.go('app.recipe',{tab:1,productId:productId, recipeId:recipeId}, {reload:true});
                            scope.DialogService.$mdDialog.hide()
                        }
                    },
                }

                this.DialogService.fromTemplateV2(
                    './views/angular/app/components/offer/partials/dialog/recipe-item.html',
                    options
                )
            });
    }

    goRecipe(productId, recipeId = null){
        this.$state.go('app.recipe',{tab:1,productId:productId, recipeId:recipeId}, {reload:true});
    }
}

StockManagementController.$inject = ["API", "$auth", "$http", "$scope", "$rootScope", "$stateParams", "$state","$translate", "$location", "$window", "$q", "$mdBottomSheet", "$mdDialog", "ToastService", "$filter", "DialogService", "FileUploader"];
const StockManagementComponent = {
    templateUrl: "./views/angular/app/components/stock-management/stock-management.component.html",
    controller: StockManagementController,
    controllerAs: "vm",
    bindings: {},
};
export {StockManagementComponent, StockManagementController}
