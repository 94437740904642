import "../offer/offer.scss";
import {DialogService} from "../../../services/dialog.service";
import {getPermission, getUserInfo, me,canPermission} from "../../../utils/users";
import moment from "moment";

class OfferController {
    constructor(API,ToastService,$mdBottomSheet,$translate,$scope,DialogService,$location,$rootScope,$state,$filter) {
        "ngInject";
        this.API = API;
        this.ToastService = ToastService;
        this.$mdBottomSheet = $mdBottomSheet;
        this.$scope = $scope;
        this.DialogService = DialogService;
        this.$rootScope = $rootScope;
        this.$location = $location;
        this.$state = $state
        this.$filter = $filter
        this.$translate = $translate;
        this.editPermission = false
        this.locations = null
        this.editInvoiceNumber = null;
        this.load = false;

        this.defaultChannelProvider = null
        this.userInfo = getUserInfo();
        this.userMe = me();
        this.currentUserId = this.userInfo.uid;


        this.offers=[];

        this.offerScreen = null;
        //this.offerTabScreen = 'list';
        this.offerDetail = {}
        this.processStatus = {}
        this.offerDiscountType = 'rate'
        this.offerDiscount = 0
        this.selectedCustomer=null;

        this.convertToOffer =null
        this.showAddProductCard = false;
        this.showDiscountCard = false;

        this.offerStatus = 'edit';
        this.quick = false;
        this.isReversed = false;

        this.priceFilter = false;
        this.quantityFilter = false;
        this.rejectFilter = false;

        this.filter = {
            offerId         : null,
            offerTypes      : [this.offerType],
            processWait     : [],
            customerCode    : null,
            term            : null,
            invoiceNumber   : null,
            expire          : null,
            status          : 'wait',
            limit           : 20,
            page            : 1,
            filter: {
                customer: [],
                freeQuantity: [],
                documentQuantity: [],
                lastQuantity: [],
                firstQuantity: [],
                discount: [],
                documentPrice: [],
                lastPrice: [],
                firstPrice: [],
                purchaseRequest: [],
                customerContacted: [],
                acceptReceiving: [],
                receiving: [],
            }
        };
        this.filterOfferType = null;

        this.quickButtonPerm = false;

        this.filterInvetory = [];

        // belkide inventoryden almak daha iyi olur
        this.quickProcessFilter = {
            sales:[
                {label:'Carisi Onaylanmamış',filter:{customer:['wait','empty']}},
                {label:'İskonto Onaylanmamış',filter:{discount:['wait','empty']}},
            ],
            purchase:[
                {label:'Mal Kabulü Devam Eden',filter:{receiving:['continue']}},
                {label:'Mal Kabulü Tamamlanmış, Arızalı Giriş Var',filter:{receiving:['done','reject']}},
                {label:'Mal Kabul Adet Farkları Var',filter:{receiving:['conflict','reject']}},
                {label:'Teklif Süreci Tamamlannış, Belge Durumu Bekleyen',filter:{firstPrice:['done'],firstQuantity:['done'],documentQuantity:['continue'],documentPrice:['continue']}},
                {label:'Carisi Onaylanmamış',filter:{customer:['wait','empty']}},
                {label:'Mal Kabulü Bekleyen',filter:{receiving:['continue'],firstPrice:['continue'],firstQuantity:['continue']}},
                {label:'Muhasebe Bekleyen',filter:{receiving:['done'],firstPrice:['continue'],firstQuantity:['continue']}},
                {label:'Satın Alma Bekleyen',filter:{receiving:['done'],documentQuantity:['done'],documentPrice:['done'], firstPrice:['continue'],firstQuantity:['continue']}},
            ],
            'return_inside|return_outside':[
                {label:'Mal Kabulü Devam Eden',filter:{receiving:['continue']}},
                {label:'Mal Kabulü Tamamlanmış, Arızalı Giriş Var',filter:{receiving:['done','reject']}},
                {label:'İade Kabul Edilmemiş ve Müşteriye Bilgi verilmemiş',filter:{acceptReceiving:['reject'],customerContacted:["wait"]}},
                {label:'Mal Kabul Adet Farkları Var',filter:{receiving:['conflict','reject']}},
            ],
            'missing_action':[
                {label:'Tedarik Edilemeyen ve Müşteri İle Görüşülmemiş',filter:{purchaseRequest:['failed','reject'],customerContacted:["wait"]}},
                {label:'Tedarik Bekleyen',filter:{purchaseRequest:['wait']}},
            ]
        };
    }

    $onInit() {
        this.quickButtonPerm = canPermission('offer-quick-sales-approve')
        let parent = this;
        this.API.all("shipment")
            .customGET()
            .then((response) => {
                this.shipmentProviders = response.data.list
            });

        this.API.all("location")
            .customGET()
            .then((response) => {
                if (response.errors === false) {
                    this.locations = response.data.location;
                }
            });

        this.API.all("action/get-payment-types")
            .post(null, {})
            .then((response) => {
                parent.paymentTypes = response.data.types;
            });

        this.$scope.$on('selectProductEvent', function (event, selectProduct){
            if (parent.selectedTabIndex == parent.wrapperTabIndex) {
                parent.addOfferItem(selectProduct.id);
            }
        });
    }

    $onChanges(data){
        this.filter.offerTypes = [this.offerType]
        if (data?.offerId?.currentValue  && data?.offerId?.currentValue !== data.offerId.previousValue){
            this.showOfferDetail(data?.offerId?.currentValue)

            if (data?.selectBarcode?.currentValue && data?.selectBarcode?.currentValue !== data.selectBarcode.previousValue) {
                this.searchProduct()
            }

        }else {
            this.getOffers();
            this.getFilterInventory()
        }
    }

    openMenu($mdMenu, ev) {
        this.originatorEv = ev;
        $mdMenu.open(ev);
    };
    clearFilter(){
        this.filter = {
            offerId         : null,
            offerTypes      : [this.offerType],
            processWait     : [],
            customerCode    : null,
            customer        : null,
            transactionId   : null,
            invoiceNumber   : null,
            expire          : null,
            status          : null,
            limit           : 20,
            page            : 1,
        };
        this.clearProcessFilter();
        this.filterOfferType = null
        this.priceFilter = false;
        this.quantityFilter = false;
        this.rejectFilter = false;
    }

    clearProcessFilter() {
        this.filter.filter = {
            customer: [],
            freeQuantity: [],
            documentQuantity: [],
            lastQuantity: [],
            firstQuantity: [],
            discount: [],
            documentPrice: [],
            lastPrice: [],
            firstPrice: [],
            purchaseRequest: [],
            customerContacted: [],
            acceptReceiving: [],
            receiving: [],
        }
    }

    showOfferDetail(offerId){
        this.API.all('offer/' + offerId)
            .customGET()
            .then((response)=>{
                if (response.response.status ===204){
                    // burası saçma oldu hiç girmemesi gerek orası ayarlanınca ihtiyaç kalmayacak
                    this.getOffers()
                    return 1;
                }
                this.getAvailableCurrency(response.data.list.currency)
                this.prepareOfferDetail(response.data.list)
            })
    }

    showOfferProcess(header,process,c){
        header +=' Süreçleri'
        let d = this.DialogService;
        let options = {
            controllerAs      : 'vm',
            bindToController  : true,
            clickOutsideToClose: true,
            escapeToClose: true,
            onShowing         : function (scope, element, options, controller){
                scope.process = process;
                scope.header = header;
                scope.DialogService = d;
                scope.cancel = ()=>{
                    scope.DialogService.$mdDialog.hide()
                }
            },
        }

        this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/offer-process-dialog.html',
            options
        )
    }
    showOfferItemAnalytics(product){
        this.API.all("action/analytics")
            .customGET(null,{
                productId: product.productId,
                subProductId: product.subProductId,
                customerId: product.customerId,
            })
            .then((response) => {
                let d = this.DialogService;
                let options = {
                    controllerAs      : 'vm',
                    bindToController  : true,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    onShowing         : function (scope, element, options, controller){
                        scope.analytics = response.data.analytics;
                        scope.DialogService = d;
                        scope.cancel = ()=>{
                            scope.DialogService.$mdDialog.hide()
                        }
                    },
                }
                this.DialogService.fromTemplateV2(
                    './views/angular/app/components/offer/partials/dialog/offer-item-analytics-dialog.html',
                    options
                )
            });
    }

    searchCustomerBarcode() {
        let parent = this;
        var options = {
            controller        : "CustomersController",
            controllerAs      : 'vm',
            locals            : {
                dialog          : true,
                selectedChannelProvider : this.defaultChannelProvider?.provider
            },
            bindToController  : true,
        };
        let customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/customers/partials/customer-search.component.html',
            options
        );
        customerDialogPromise.then(response => {
            if (this.offerTabScreen !== 'newOffer'){
                this.updateOffer(this.offerDetail.offerId, {
                    customerId          : response.customerId,
                    deliveryAddressId   : response?.deliveryAddress?.addressId,
                    invoiceAddressId    : response.invoiceAddress?.addressId
                })
                return;
            }
            this.selectedCustomer = response;
        }).catch(err=>{
            console.log(err)
        });
    }

    showAddressDetail() {
        let parent = this

        let options = {
            controllerAs      : 'vm',
            bindToController  : true,
            clickOutsideToClose: true,
            escapeToClose: true,
            onShowing         : function (scope, element, options, controller){
                scope.DialogService     = parent.DialogService;
                scope.API               = parent.API
                scope.customerId        = parent.offerDetail.customerId;
                scope.customer          = parent.offerDetail.customer;
                scope.customerBalance   = parent.offerDetail.customerBalance;
                scope.deliveryAddress   = parent.offerDetail.deliveryAddress;
                scope.invoiceAddress    = parent.offerDetail.invoiceAddress;
                scope.process           = parent.offerDetail.processes.filter(p => p.processType === 'customer-control')[0]
                scope.addressList       = {};
                scope.selectedDeliveryAddressId = parent.offerDetail?.deliveryAddress?.id
                scope.selectedInvoiceAddressId = parent.offerDetail?.invoiceAddress?.id

                scope.getAddress = () => {
                    scope.API.all(`customer/${scope.customerId}/address`)
                        .customGET()
                        .then((response) => {

                            scope.addressList.invoiceAddress = response.data.list.invoiceAddress
                            scope.addressList.deliveryAddress = response.data.list.deliveryAddress

                        });
                }

                if (scope.process?.canChange){
                    scope.getAddress()
                }

                scope.cancel = ()=>{
                    scope.DialogService.$mdDialog.hide()
                }
                scope.approveAddress =() =>{
                    parent.updateOffer(parent.offerDetail.offerId,{
                        deliveryAddressId   : scope.selectedDeliveryAddressId,
                        invoiceAddressId    : scope.selectedInvoiceAddressId,
                    }).then(response =>{
                        scope.deliveryAddress   = parent.offerDetail.deliveryAddress;
                        scope.invoiceAddress    = parent.offerDetail.invoiceAddress;
                    })
                }
            },
        }

        this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/customer-address-detail.html',
            options
        )
    }

    updateOffer(offerId, {
        itemId,
        customerId,
        deliveryAddressId,
        invoiceAddressId,
        firstQuantity,
        firstPrice,
        documentQuantity,
        documentPrice,
        itemCurrency,
        lastQuantity,
        freeQuantity,
        lastPrice,
        priceDiscount,
        rateDiscount,
        invoiceNumber,
        shipment,
        note,
        estimatedShipmentDate,
        expiryDate,
        vatPercent,
        productUpdate,
        itemNote
    }) {

        let updateData = {};
        if (itemId) {
            updateData['itemId'] = itemId
            if (firstQuantity) {
                updateData['firstQuantity'] = firstQuantity
            }
            if (firstPrice) {
                updateData['firstPrice'] = firstPrice
            }
            if (documentQuantity) {
                updateData['documentQuantity'] = documentQuantity
            }
            if (documentPrice) {
                updateData['documentPrice'] = documentPrice
            }
            if (itemCurrency) {
                updateData['itemCurrency'] = itemCurrency
            }
            if (lastQuantity) {
                updateData['lastQuantity'] = lastQuantity
            }
            if (freeQuantity) {
                updateData['freeQuantity'] = freeQuantity
            }
            if (lastPrice) {
                updateData['lastPrice'] = lastPrice
            }
            if (vatPercent) {
                updateData['vatPercent'] = vatPercent
            }
            if (productUpdate) {
                updateData['productUpdate'] = productUpdate
            }
            if (itemNote) {
                updateData['itemNote'] = itemNote
            }
        }

        if (customerId) {
            updateData['customerId'] = customerId
        }

        if (deliveryAddressId) {
            updateData['deliveryAddressId'] = deliveryAddressId
        }

        if (invoiceAddressId) {
            updateData['invoiceAddressId'] = invoiceAddressId
        }

        if (priceDiscount) {
            updateData['priceDiscount'] = priceDiscount
        }
        if (rateDiscount) {
            updateData['rateDiscount'] = rateDiscount
        }
        if (invoiceNumber) {
            updateData['invoiceNumber'] = invoiceNumber
        }
        if (shipment) {
            updateData['shipment'] = shipment
        }
        if (note) {
            updateData['note'] = note
        }
        if (estimatedShipmentDate) {
            updateData['estimatedShipmentDate'] = estimatedShipmentDate
        }
        if (expiryDate) {
            updateData['expiryDate'] = expiryDate
        }
        updateData['note'] = this.offerDetail.newNote;
        updateData['invoiceNumber'] = this.offerDetail.newInvoiceNumber;
        updateData['expiryDate'] = this.offerDetail.newExpiryDate;
        updateData['estimatedShipmentDate'] = this.offerDetail.newEstimatedShipmentDate;
        updateData['shipment'] = this.offerDetail.newShipment;
        updateData['locationId'] = this.offerDetail.newLocation;
        updateData['actionType'] = this.offerDetail.actionType;
        updateData['offerCurrency'] = this.offerDetail.currentCurrency;
        updateData['invoiceDate'] = this.offerDetail.newInvoiceDate;
        updateData['paymentTypeId'] = this.offerDetail.newPaymentTypeId;

        return  this.API.all(`offer/${offerId}`)
            .customPUT(updateData)
            .then((response) => {
                this.prepareOfferDetail(response.data.list)
            }).catch(response => {
              this.ToastService.showHtml('error', this.$translate.instant('MISTAKE'))
              console.log(response);
          })
    }

    offerApprove(process, itemId = null) {
        this.API.all(`offer-process/${process}/approve`)
            .customPUT({
                offerId: this.offerDetail.offerId,
                itemId: itemId
            })
            .then((response) => {
                this.prepareOfferDetail(response.data.list)

            })
    }
    offerSave(offer, itemId = null) {
        let offerId = offer.offerId;
        let offerRequest = {
            note : offer.newNote,
            invoiceNumber : offer.newInvoiceNumber,
            expiryDate : offer.newExpiryDate,
            estimatedShipmentDate : offer.newEstimatedShipmentDate,
            shipment : offer.newShipment,
            locationId : offer.newLocation,
            actionType : offer.actionType,
            offerCurrency : offer.currentCurrency,
            invoiceDate : offer.newInvoiceDate,
            paymentTypeId : offer.newPaymentTypeId,
        }

        return  this.API.all(`offer/${offerId}`)
            .customPUT(offerRequest)
            .then((response) => {
                this.getAvailableCurrency(response.data.list.currency)
                this.prepareOfferDetail(response.data.list)
                this.ToastService.success(this.$translate.instant('OFFER_SAVED'))
            })
    }

    getOffers(page){
        if (page){
            this.filter.page = 1;
        }
        if (this.filterOfferType){
            this.filter.offerTypes = [this.filterOfferType]
        }

        // sayfa refreshlenmişse event çalışmıyo o yüzden default  bişey yapmak gerek
        if (!this.filter.offerTypes){
            /*
            this.offerScreen = this.$location.search().screen;
            this.filter.offerTypes = this.type[this.offerScreen]
            this.getOffers()
             */
        }

        let filter = {
            offerType       : this.filter.offerTypes.join('|'),
            processWait     : this.filter.processWait.join('|'),
            customerCode    : this.filter.customerCode,
            customer        : this.filter.customer,
            term            : this.filter.term,
            invoiceNumber   : this.filter.invoiceNumber,
            productBarcode  : this.filter.productBarcode,
            expire          : this.filter.expire,
            status          : this.filter.status,
            limit           : this.filter.limit,
            page            : this.filter.page,
        }

        Object.entries(this.filter.filter).filter(([process,f])=>{
            if (f.length>0){
                filter[process] = f.join('|')
            }
        })

        this.API.all('offer')
            .customGET(null,filter)
            .then((response) => {
                this.offerTabScreen ='list'
                this.priceFilter =false
                this.quantityFilter =false
                this.rejectFilter =0
                if (response.response.status === 204){
                    this.offers = [];
                    return;
                }
                this.offers = response.data.list.offers;
                this.showCount = response.data.list.showCount;
                this.total =response.data.list.total
                this.pageList = [];
                let max = Math.ceil(response.data.list.total / response.data.list.limit)
                max = max ===0 ? 1 : max;
                for (let p=1; p <=max;++p){
                    this.pageList.push(p)
                }
            })
            .catch(function () {
                this.subDisabled = false;
            });
    }

    /** @deprecated */
    searchBarcode() {
        this.API.all("search")
            .customGET(null, {
                barcode: this.productBarcode,
                type: 'product',
                size: 25,
                accountingProvider: this.defaultChannelProvider?.accountingName,
            })
            .then((response) => {

                let term = this.productBarcode;
                this.productBarcode = null;

                if (response.response.status === 204) {
                    return
                }

                let result = response.data

                if (result.listCount === 0){
                    this.ToastService.showHtml('error', this.$translate.instant('SEARCH_DATA_NOT_PRODUCT'))
                    return;
                }

                if (result.matchType === 'match'){
                    this.addOfferItem(result.list[0].id,result.list[0].subId)
                    return;
                }

                this.searchItemList(term, result.list,result.listCount)
            })
    }

    /** @deprecated */
    searchItemList(term,products,listCount){
        let options = {
            controller: 'OfferSearchItemListController',
            controllerAs: 'vm',
            locals: {
                products:products,
                term:term,
                listCount:listCount
            },
            bindToController: true,
        }
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/item-list.component.html',
            options
        ).then(resp=>{
            this.addOfferItem(resp.productId,resp.subProductId)
        })
    }

    addOfferItem(productId,subProductId=null){

        let data = {
            productId: productId,
            subProductId: subProductId,
            quantity: 1
        }

        if (this.offerTabScreen=== 'newOffer'){
            data['customerId'] = this.selectedCustomer?.customerId
            data['offerType'] = this.newOfferType
            data['deliveryAddressId'] = this.selectedCustomer?.deliveryAddress?.addressId
            data['invoiceAddressId'] = this.selectedCustomer?.invoiceAddress?.addressId
            data['provider'] = this.defaultChannelProvider?.provider

        }
        console.log(data)

        return  this.API.all(`offer/${this.offerTabScreen === 'newOffer' ? '_' : this.offerDetail.offerId}`)
            .customPOST(data)
            .then((response) => {
                this.prepareOfferDetail(response.data.list)
                this.getAvailableCurrency(response.data.list.currency)
                return response.data.list
            })
    }
    deleteOffer(itemId =null){
        this.API.all(`offer/${this.offerDetail.offerId}`)
            .customDELETE(null,{
                itemId: itemId
            })
            .then((response) => {
                if (response.response.status===204){
                    this.getOffers()
                }
                this.prepareOfferDetail(response.data.list)
            })
    }

    prepareOfferDetail(detail){
        this.offerTabScreen = 'detail'
        this.offerDetail = detail
        this.offerDocumentCount = detail.document.length;
        this.offerStatus = 'edit'
        this.offerDetail.newInvoiceNumber = this.offerDetail.invoiceNumber
        this.offerDetail.newShipment = this.offerDetail.shipmentCode
        this.offerDetail.newLocation = this.offerDetail.locationId
        this.offerDetail.newNote = this.offerDetail.note
        this.offerDetail.newEstimatedShipmentDate = this.offerDetail.estimatedShipmentDate
        this.offerDetail.newExpiryDate = this.offerDetail.expiryDate
        this.offerDetail.discountPrice = this.offerDetail.discountPrice || 0;
        this.offerDetail.newDiscountedPrice = this.offerDetail.discountedPrice || 0
        this.offerDetail.newDiscountedRate =  parseFloat(this.offerDetail.discountRate || 0)
        this.offerDetail.currentCurrency = this.offerDetail.currency
        this.offerDetail.newInvoiceDate = this.offerDetail.invoiceDate
        this.offerDetail.newPaymentTypeId = this.offerDetail.paymentType

        if (this.offerDetail.readOnly){
            this.offerStatus = 'readOnly'
        }

        this.processStatus = {}

        this.offerDiscount = this.offerDetail.discountedPrice
        if (this.offerDiscountType === 'rate'){
            this.offerDiscount = this.offerDetail.newDiscountedRate
        }
        this.changeDiscountType()
        this.offerDetail?.processStatus?.forEach(p => {
            this.processStatus[p.processType] = p;
        })
        this.offerDetail?.processes?.forEach(p=>{
            this.offerDetail.processes[p.processType] = p
        })
        this.offerDetail.items.forEach(i=>{
            i.newFirstPrice         = parseFloat(i.firstPrice)
            i.newFirstQuantity      = parseFloat(i.firstQuantity)
            i.newDocumentPrice      = parseFloat(i.documentPrice)
            i.newDocumentQuantity   = parseFloat(i.documentQuantity)
            i.newLastPrice          = parseFloat(i.lastPrice)
            i.newLastQuantity       = parseFloat(i.lastQuantity)
            i.newFreeQuantity       = parseFloat(i.freeQuantity)
            i.newRejectedQuantity   = parseFloat(i.rejectedQuantity)
            i.newVat                = parseFloat(i.vat)
            i.newCurrency           = i.currency
            i.newItemNote           = i.note
            i.show                  = true

            if (i.priceAlert &&  this.offerDetail.offerType === 'purchase'){
                i.showPurchasePriceAlert= true
            }
            if (i.priceAlert &&  this.offerDetail.offerType === 'sales'){
                i.showSalesPriceAlert= true
            }

            i.processes.forEach(p=>{
                p?.document?.forEach(d=>d.src = d.url)

                i.processes[p.processType] = p
            })
        })

        if (this.offerDetail.offerType === 'sales'){
            this.showDiscountCard = true;
        }

        if (this.offerDetail.offerType === 'sales' || this.offerDetail.offerType === 'purchase'){
            this.showAddProductCard = true;
        }
    }
    changeOrder() {
        this.isReversed = !this.isReversed;
        this.offerDetail.items.reverse();
    }

    changeDiscountType(){
        this.offerDiscount = this.offerDetail.discountPrice
        if (this.offerDiscountType === 'rate'){
            this.offerDiscount = this.offerDetail.discountRate
        }

    }
    updateItem(offerId, itemId, key, newData, oldData,options) {
        //if (newData === oldData) return

        let data = {[key]: newData};
        if (itemId) {
            data['itemId'] = itemId
        }
        if (options?.productUpdate){
            data['productUpdate'] = options.productUpdate
        }
        this.updateOffer(offerId, data)
    }
    setDiscount(){
        let field = 'priceDiscount'
        if (this.offerDiscountType == 'rate'){
            field = 'rateDiscount';
        }
        this.updateOffer(this.offerDetail.offerId,{[field]:this.offerDiscount})
    }

    completeOffer(){
        this.API.all(`offer/${this.offerDetail.offerId}/complete`)
            .customPUT({
                quick:this.quick
            })
            .then((response) => {
                this.quick =false;
                if (response.response.status === 204){
                    this.back()
                }
            })
    }
    completeMissingOffer(){
        let parent  =this;
        let d = this.DialogService;
        let options = {
            controllerAs      : 'vm',
            bindToController  : true,
            clickOutsideToClose: true,
            escapeToClose: true,
            onShowing         : function (scope, element, options, controller){
                scope.offer = parent.offerDetail
                scope.DialogService = d;
                scope.cancel = ()=>{
                    scope.DialogService.$mdDialog.hide(false)
                }
                scope.approve = () => {
                    scope.DialogService.$mdDialog.hide(true)
                }
            },
        }

        this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/missing-action-confirm-dialog.html',
            options
        ).then(result=>{
            if (result){
                this.completeOffer();
            }
        })
    }
    newOfferScreen(offerType){
        this.offerTabScreen = 'newOffer'
        if (offerType === 'newReturnOffer'){
            this.offerTabScreen = 'newReturnOffer'
        }
        this.newOfferType = offerType;
    }

    createReturnedOffer(partialReturned, note = '', offerId = null) {
        if (offerId){
            this.convertToOffer = '_'
        }
        if (!this.convertToOffer) {
            this.ToastService.showHtml('warn', this.$translate.instant('RETURN_NUMBER_REQUIRED'))
            return;
        }
        this.API.all("action-process/returned/" + this.convertToOffer)
            .customDELETE(null, {
                partialReturned: partialReturned ? 1 :0,
                note: note,
                offerId: offerId
            }).then((response) => {
            if (response.response.status === 207) {
                this.getOffers()
            }
        })
    }

    cancelOffer(offerId) {
        this.DialogService.confirm(
            this.$translate.instant('WARNING'),
            this.$translate.instant('WANT_TO_DELETE_OFFER')
        ).then(() => {
              this.API.all(`offer/${this.offerDetail.offerId}`)
                .customDELETE().then((response) => {
                  this.getOffers()
              })
          },
          () => {
          }
        );
    }

    offerRevoke(process, itemId = null,processId) {

        let url = `offer-process/${process}/revoke`;
        let data = {
            offerId: this.offerDetail.offerId,
            itemId: itemId
        }
        if (process === 'receiving-control'){
            url = `receiving/${processId}/revoke`
            data = {itemId: itemId}
        }

        this.API.all(url)
            .customPUT(data)
            .then((response) => {
                this.showOfferDetail(this.offerDetail.offerId);
            })
    }

    showVatList(vatList){
        let d = this.DialogService;
        let options = {
            controllerAs      : 'vm',
            bindToController  : true,
            clickOutsideToClose: true,
            escapeToClose: true,
            onShowing         : function (scope, element, options, controller){
                scope.vatList = vatList;
                scope.DialogService = d;
                scope.cancel = ()=>{
                    scope.DialogService.$mdDialog.hide()
                }
            },
        }

        this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/vat-list-dialog.html',
            options
        )
    }

    back(){
        this.offerTabScreen = 'list';
        this.getOffers()
    }

    searchProduct(){
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                term: this.selectBarcode,
                id: this.selectProductId,
            }
        })
    }

    completeQuick(){
        this.DialogService.confirm(
            this.$translate.instant('WARNING'),
            this.$translate.instant('AUTOMATICALLY_APPROVE')
        ).then(() => {
              this.quick = true
              this.completeOffer()
          },
          () => {
          }
        );
    }

    productSetPassiveActive(productId,isActive){
        this.API.all("stock/"+productId)
            .customPUT({
                isActive:isActive
            })
            .then(() => {
                this.ToastService.success(this.$translate.instant('STOCK_CARD_UPDATED'));
            });
    }


    downloadPdf() {
        this.API.all('offer')
            .withHttpConfig({ responseType: 'arraybuffer' })
            .customGET(this.offerDetail.offerId, null, {
                'x-api-output': 'pdf',
            })
          .then((response) => {
            let blob = new Blob([response.data], { type: 'application/pdf' });
            this.previewPdf(blob)
          })
          .catch((response) => {
              if (response.data instanceof ArrayBuffer) {
                  const decoder = new TextDecoder('utf-8');
                  const errorText = decoder.decode(new Uint8Array(response.data));
                  try {
                      const errorJson = JSON.parse(errorText);
                      this.ToastService.error(errorJson.errors.message[0]);
                  } catch (e) {
                      console.error("Error parsing JSON:", e);
                  }
              } else {
                  console.error("Unexpected error format:", response);
              }
          });
    }

    printActionDocument(offerId) {
        this.API.all("offer/barcode")
            .post({offerId: offerId})
            .then((response) => {
                const printWindow = window.open('', '_blank');
                printWindow.document.write(response.data.document);
                printWindow.document.close();
                printWindow.onload = function() {
                    printWindow.print();
                    printWindow.close();
                };
                // document.getElementById('printContent').innerHTML = response.data.document;
                // window.print();
                // document.getElementById('printContent').innerHTML = '';
            })
            .catch(function (error) {
                console.error( error);
            });
    }


    previewPdf(blob) {
        var options = {
            controller        : 'PrintPreviewDialogController',
            controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals            : {
                blob:blob
            },
            bindToController  : true,
            multiple          : true,
            onShowing         : function (scope, element, options, controller) {
                controller.previewBlob(blob);
            },
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/dialogs/printPreview/printPreview.dialog.html',
            options
        );
        customerDialogPromise.catch(err=>{
            console.log(err)
        })

    }

    bulkAddItem() {
        let d = this.DialogService;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.wait = false
                scope.header = false
                scope.rowSeparator = 'enter'
                scope.colSeparator = 'tab'
                scope.spitData = [];
                scope.data = '';
                scope.DialogService = d;

                scope.addItem = (barcode) => {
                    scope.spitData = [];
                    barcode.split('\n').map((row, index) => {
                        if (scope.header && index === 0) {
                            return true;
                        }
                        let col = row.replace('\r', '').split('\t')

                        scope.spitData.push({
                            barcode: col[0],
                            quantity: col[1] ? col[1].replace(',', '.') : 1,
                            unitPrice: col[2] ? col[2].replace(',', '.') : null,
                        })

                    })
                }

                scope.send = () => {
                    scope.addItem(scope.data)
                    scope.DialogService.$mdDialog.hide(scope.spitData)
                }

                scope.cancel = ()=>{
                    scope.DialogService.$mdDialog.hide()
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/bulk-add-item.html',
            options
        );
        customerDialogPromise.then(resp => {
            if (resp.length > 0) {
                this.addOfferItemBulk(resp)
            }
        })
            .catch(err => {
                console.log(err)
            })
    }

    bulkAddItemResult(report) {
        let d = this.DialogService;
        var options = {
            //  controllerAs      : 'vm',
            //  scope             : this.$scope,
            locals: {},
            onShowing: function (scope, element, options, controller) {
                scope.DialogService = d
                scope.report = report
                scope.addProductList = {}
                scope.addProductListCount = 0
                scope.tryErrorRows =true

                scope.listControl=() =>{
                    scope.addProductListCount = Object.keys(scope.addProductList).filter(b => scope.addProductList[b]).length
                }

                scope.addProduct = () => {
                    let list = Object.keys(scope.addProductList).filter(b => scope.addProductList[b]).map((barcode) => {
                        let product = scope.report.items.find(r => r.barcode === barcode)
                        return {
                            barcode: barcode,
                            quantity: product.quantity,
                            unitPrice: product.unitPrice,
                            productQuickSave: true
                        }
                    })
                    scope.DialogService.$mdDialog.hide(list)
                }

                scope.cancel = () => {
                    scope.DialogService.$mdDialog.hide()
                }
            }
        };

        var customerDialogPromise = this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/bulk-add-item-result.html',
            options
        );
        customerDialogPromise.then(resp => {
            if (resp.length > 0 ){
                this.addOfferItemBulk(resp)
            }
            console.log(resp,'<<<<<------')
        }).catch(err => {
            console.log(err)
        })
    }

    addOfferItemBulk(items){

        let data = {
            items: items,
            bulk: 1,
        }

        if (this.offerTabScreen=== 'newOffer'){
            data['customerId'] = this.selectedCustomer?.customerId
            data['offerType'] = this.newOfferType
            data['deliveryAddressId'] = this.selectedCustomer?.deliveryAddress?.addressId
            data['invoiceAddressId'] = this.selectedCustomer?.invoiceAddress?.addressId
            data['provider'] = this.defaultChannelProvider?.provider

        }

        return  this.API.all(`offer/${this.offerTabScreen === 'newOffer' ? '_' : this.offerDetail.offerId}`)
            .customPOST(data)
            .then((response) => {
                const {list} = response.data
                if (list?.isNew && !list?.createOffer){
                    this.bulkAddItemResult(response.data.list.bulkReport)
                    return
                }

                this.prepareOfferDetail(response.data.list)
                this.bulkAddItemResult(response.data.list.bulkReport)
                return list;
            })
    }

    getFilterInventory() {
        this.API.all("offer")
            .customGET("inventory/filter", {
                offerType: this.filter.offerTypes.join('|'),
            })
            .then((response) => {
               this.filterInvetory = response.data.list;
            })
    }


    selectAllOptionsByModel(model,process=true) {

        if (model === 'processWait'){
            this.filter.processWait = this.filterInvetory.processList.data.map(d=>d.processType)
        }

        if (process) {
            const full = {
                customer: ['empty','continue','done'],
                freeQuantity: ['continue','done'],
                documentQuantity: ['continue','done'],
                lastQuantity: ['continue','done'],
                firstQuantity: ['continue','done'],
                discount: ['empty','continue','done'],
                documentPrice: ['continue','done'],
                lastPrice: ['continue','done'],
                firstPrice: ['continue','done'],
                purchaseRequest: ['wait','done','reject','failed'],
                customerContacted: ['done','wait'],
                acceptReceiving: ['accept','reject'],
                receiving:['wait','continue','conflict','reject','done'],
            }
            this.filter.filter[model] = full[model]
        }
    }

    deselectAllOptionsByModel(model,process = null) {
        if(process){
            this.filter.filter[model] = [];
        }else {
            this.filter[model] = [];
        }
    }

    getAvailableCurrency(currency){
        this.API.all('settings/currency')
            .customGET(null,{
                name: currency,
            })
            .then((response) => {
                this.availableCurrency = response.data.list[0].exchangeRate;
            })
    }
    showBottomSheet(isShowCamera) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                isShowCamera: isShowCamera
            }
        })
    }

    showProductBottomSheet(productId) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/bottom-view/bottom-view.component.html",
            controller: "BottomViewController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                id: productId
            }
        })
    }


    showItemRecipe(productName,recipeId){
        let parent = this;
        let d = this.DialogService;
        this.API.all('recipe/'+recipeId)
            .customGET()
            .then((response) => {
                let options = {
                    controllerAs      : 'vm',
                    bindToController  : true,
                    clickOutsideToClose: true,
                    escapeToClose: true,
                    onShowing         : function (scope, element, options, controller){
                        scope.productName = productName;
                        scope.items = response.data.list.items;
                        scope.DialogService = d;
                        scope.cancel = () => {
                            scope.DialogService.$mdDialog.hide()
                        }
                        scope.goRecipe = (productId, recipeId=null) => {
                            parent.$state.go('app.recipe',{tab:1,productId:productId, recipeId:recipeId}, {reload:true});
                            scope.DialogService.$mdDialog.hide()
                        }
                    },
                }

                this.DialogService.fromTemplateV2(
                    './views/angular/app/components/offer/partials/dialog/recipe-item.html',
                    options
                )
            });
    }

    exportExcel() {
        let parent = this
        this.API.all('offer')
            .withHttpConfig({ responseType: 'arraybuffer' })
            .customGET(this.offerDetail.offerId, null, {
                'x-api-output': 'excel',
            })
            .then(function(response) {
                var blob = new Blob([response], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = parent.offerDetail.transactionId+'.xlsx';
                link.click();
            })
            .catch(function(error) {
                console.error('Error fetching CSV:', error);
            });
    }
    goFromReceiving(processId){
        this.$filter('copyText')(processId);
        if (processId){
            this.options = {tab: 0, processId: processId }
            this.$state.go('app.receiving',this.options);
        }
    }

    showGallery(gallery) {
        let parent = this;
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/upload/gallery.component.html",
            controller: "GalleryController",
            controllerAs: "vm",
            bindToController: true,
            locals: {
                gallery: gallery
            }
        }).then(response => {
            parent.showOfferDetail(parent.offerDetail.offerId)
        })
            .catch(function (error) {
                parent.showOfferDetail(parent.offerDetail.offerId)
            });
    }
    showDetailList(lotList){
        let parent = this;
        this.DialogService.fromTemplateV2(
            './views/angular/app/components/offer/partials/dialog/receiving-lot-process.html',
            {
                onShowing: function (scope, element, options, controller) {
                    scope.lotList       = lotList;
                    scope.cancel = () => {
                        scope.DialogService.$mdDialog.hide()
                    }
                }
            }
        ).catch(err => {
            console.log(err)
        });
    }

    resolve(receivingId){
        return this.API.all(`receiving/${receivingId}/resolve`)
            .patch()
            .then((response) => {
                this.showOfferDetail(this.offerDetail.offerId)
            })
    }

    getUploadModal(serviceId, service) {
        this.$mdBottomSheet.show({
            templateUrl: "./views/angular/app/components/upload/upload.component.html",
            controller: "UploadController",
            controllerAs: "vm",
            bindToController: true,
        }).then(gallery => {
            if (!gallery) return;

            const uploadPromises = gallery.map((item) => {
                return this.API.all("document/upload")
                    .customGET(null, {
                        fileName: item?.filename, size: item?.size, type: item?.type,
                    })
                    .then((response) => {
                        const {list} = response.data;
                        return this.saveGallery(list.uri, item.file).then(resp => {
                            return this.saveDocument({
                                path: list?.path,
                                size: list?.size,
                                documentType: item?.type,
                                alias: list.alias
                            }).then(resp => {
                                return this.assignDocument(resp.data.list.documentId, service, serviceId);
                            });
                        });
                    });
            });

            Promise.all(uploadPromises).then(() => {
                this.showOfferDetail(this.offerDetail.offerId);
            });
        });
    }

    assignDocument(documentId, service, serviceId) {
        this.API.all("document/assign")
            .customPUT({
                serviceId: serviceId,
                documentId: documentId,
                service: service,
            })
            .then((response) => {
                this.ToastService.success(this.$translate.instant('FILE_UPLOADED'));
            });
    }

    saveGallery(uri, file) {
        this.load = true;
        let parent = this;
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open('PUT', uri, true);
            //  xhr.setRequestHeader('Content-Type', file.type);

            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve(true)
                    parent.load = false;
                    console.log('File uploaded successfully!');
                } else {
                    reject(xhr.statusText)
                    console.error('Error uploading file: ', xhr.statusText);
                }
            };

            xhr.onerror = function () {
                reject(xhr.statusText)
                console.error('Error uploading file: ', xhr.statusText);
            };

            xhr.send(file);
        })
    }

    saveDocument({path, documentType, size, alias}) {
        return this.API.all("document")
            .post({
                path: path,
                documentType: documentType,
                size: size,
                alias: alias
            })
    }

    onlyConflict(filter){
        this[filter] = !this[filter]
        this.offerDetail.items.forEach(item => {
            item.show = true;
        });

        if (!this.priceFilter && !this.quantityFilter && !this.rejectFilter){
            return;
        }

        this.offerDetail.items.forEach(item => {
            const filteredConflicts = item.conflict.filter(i => {
                console.log(filter,this.priceFilter , i.type === 'price' ,
                    this.quantityFilter , i.type === 'quantity' ,
                    this.rejectFilter , i.type === 'reject')
                return    (this.priceFilter && i.type === 'price') ||
                    (this.quantityFilter && i.type === 'quantity') ||
                    (this.rejectFilter && i.type === 'reject')
                }
            );
            item.show = filteredConflicts.length > 0;
        });

    }

    showDetail(term) {
        if (!term){
            this.ToastService.showHtml('warn', this.$translate.instant('ENTER_THE_VALUE_SEARCH'))
            return false
        }
        this.API.all('search')
            .doGET(null,{term:term,type:'offerOrderActionRelation'})
            .then((response) => {
                const list = response.data.list[0];

                if (response.response.status !== 200 ||( !list?.orderId && !list?.actionId)) {
                    this.ToastService.showHtml('warn', this.$translate.instant('SEARCHED_VALUE_WAS_NOT_FOUND'))
                    return true;
                }
                if (list?.orderId){
                    let options = {
                        controller: 'InvoicesDetailController',
                        controllerAs: 'vm',
                        locals: {
                            orderId: list.orderId
                        },
                        bindToController: true,
                        onShowing: function (scope, element, options, controller) {
                            controller.getOrderDetail(controller.orderId)
                        },
                    }
                    var invoiceDetail = this.DialogService.fromTemplateV2(
                        './views/angular/app/components/invoices-detail/invoices-detail.component.html',
                        options
                    )
                    return true;
                }

                if (list?.actionId){
                    let options = {
                        controller: 'ActionDetailController',
                        controllerAs: 'vm',
                        locals: {
                            id: list.actionId
                        },
                        bindToController: true,
                        onShowing: function (scope, element, options, controller) {
                            controller.getDetail(controller.id)
                        },
                    }
                    this.DialogService.fromTemplateV2(
                        './views/angular/app/components/order-lists/partials/action-detail.component.html',
                        options
                    )
                }

            })

    }

    changeExtra({settings, value, offerId, itemId}) {
        if (settings === 'prepare'){
            this.DialogService.confirm(
                this.$translate.instant('PLACE_COLLECT_ITEMS_BACK_SHELF'),
                this.$translate.instant('CANNOT_BE_UNDONE')
            )
                .then((result) => {
                    if (result){
                        this.sendChangeExtra({settings, value, offerId, itemId})
                    }

                });
            return true
        }
        this.sendChangeExtra({settings, value, offerId, itemId})
    }
    sendChangeExtra({settings, value, offerId, itemId}){
        return this.API.all(`offer/${offerId}/extra`)
            .patch({
                itemId: itemId,
                settings: {[settings]: value},
            })
            .then((response) => {
                this.prepareOfferDetail(response.data.list)
            })
    }

    selectQuickFilter(filter){
        if (filter === 'custom'){
            this.filterCollapsed = true
            return;
        }
        this.clearProcessFilter();
        Object.entries(filter.filter).forEach(([key, value]) => {
            this.filter.filter[key] = value;
        })
    }
}

OfferController.$inject =  ["API","ToastService", "$mdBottomSheet","$translate","$scope","DialogService", "$location", "$rootScope", "$state", "$filter"]
const OfferComponent = {
    templateUrl: "./views/angular/app/components/offer/offer.component.html",
    controller: OfferController,
    controllerAs: "vm",
    bindings: {
        offerType: '<',
        selectedTabIndex: '<',
        wrapperTabIndex: '@',
        offerId:'<',
        selectBarcode:'<'
    },
};

export {OfferComponent, OfferController}
