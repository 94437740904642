import "../invoices/invoices.scss";
import {getUserInfo,canPermission} from "../../../utils/users";

class InvoicesDetailController {
  constructor(
    API,
    ToastService,
    $timeout,
    i18nService,
    $auth,
    $state,
    DialogService,
    UserService,
    $templateCache,
    $scope,
    $stateParams,
    $translate,
    $location,
    $rootScope,
    $mdBottomSheet
  ) {
    "ngInject";
    this.API = API;
    this.ToastService = ToastService;
    this.DialogService = DialogService;
    this.$timeout = $timeout;
    this.$mdBottomSheet = $mdBottomSheet;

    this.i18nService = i18nService;
    this.state = $state;
    this.$auth = $auth;
    this.UserService = UserService;
    this.$templateCache = $templateCache;
    this.$scope = $scope;
    this.$stateParams = $stateParams;
    this.$translate = $translate;
    this.$location = $location;
    this.$rootScope = $rootScope;

    this.responsibleUsers = this.UserService.getResponsibleUsers();
    this.video = undefined;
    this.channelId = undefined;
    this.invoiceDate = null
    this.invoiceValidityDate = null;
    this.takeBuyingProduct = [] ;
    this.productNote = [] ;
    this.searchingProduct = null ;
    this.buyingProductQuantity = [];
    this.locations = null
    this.orderDetail = {};
    this.branches = [];
    this.buyingProductQuantityInList = [];
    this.missingProducts = null
    this.buyingProductNewPrice = [];
    this.buyingProductsInArr = [] ;
    this.selectedLots = [];
    this.basketProductsArr = [];
    this.userInfo = getUserInfo();
    this.filterCollapsed = true;
    this.show_vat_included = true;
    this.canPermission = canPermission;
    this.ownerUsers = this.UserService.getAllUsers();
    this.isOrderCurrency = true;

    this.column = 'ts_order_date';
    this.sortMode = 'desc';
    this.sortModeStr = 'arrow_drop_down';
    this.timeRange = 'lastWeek';
    this.page = 1;
    this.perPage = 10;
    this.orderId = null;

  }

  $onInit() {
    let parent = this;
    if (localStorage.buyingList != null){
      this.localStoragebuyingList = JSON.parse(localStorage.getItem('buyingList'))
      this.localStoragebuyingList.products.map((item,index) => {
        this.buyingProductsInArr.push(item.product);
        this.productNote[index] = item?.note
        this.buyingProductQuantityInList[index] = item.quantity
        this.buyingProductNewPrice[index] = item?.newPrice
      })
      this.createActionTypeId = this.localStoragebuyingList.actionId
      this.createCompanyId = this.localStoragebuyingList.companyId;
      this.createCompanyLabel = this.localStoragebuyingList.companyLabel
      this.invoiceDate = this.localStoragebuyingList.invoiceDate
      this.invoiceValidityDate = this.localStoragebuyingList.invoiceValidityDate
    }
    this.sipNo = this.$stateParams.sipNo;
    this.activeOrderlineControl = false; // false
    this.balancedProducts = [];
    this.product_is_stock = [];
    this.cb1 = [];

    this.orders = [];
    this.video = 'invoices';

    this.selectedTabIndex = _.isEmpty(this.state.params.tab) ? 0 : this.state.params.tab;

    this.getOrderDetail(this.orderId)
  }
  $onChanges(data){
    if (data.orderId && !data.orderId.isFirstChange()) {
      this.getOrderDetail(data.orderId.currentValue);
    }
  }

  onSelectTab(obj){
    this.$location.search(obj);
  }

  getOrderDetail(id){
    return  this.API.all("order/detail")
        .post({
          orderId: id,
        })
        .then((response) => {
          this.orderDetail = response.data;
          this.calculateRowColors();
        });
  }

  showDetail(id) {
    this.selectedDetailId = id;
    let options = {
      controller: 'InvoicesDetailController',
      controllerAs: 'vm',
      locals: {
        orderId: id
      },
      bindToController: true,
      onShowing: function (scope, element, options, controller) {
        controller.getOrderDetail(controller.orderId)
      },
    }
    this.DialogService.fromTemplateV2(
        './views/angular/app/components/invoices-detail/invoices-detail.component.html',
        options
    )
  }

  showListDetail(listId) {
    let options = {
      controller: "IssuesDetailController",
      controllerAs: "vm",
      bindToController: true,
      locals: {
        listId: listId
      },
      onShowing: function (scope, element, options, controller) {
        controller.getList()
      },
    }
    this.DialogService.fromTemplateV2("./views/angular/app/components/issues/partials/issues-detail.html",options)
        .then((response)=>{
        })
  }

  shipmentDetail(action) {
    let parent = this;
    let d = this.DialogService;
    this.DialogService.fromTemplateV2(
        './views/angular/app/components/order-lists/partials/shipment-message.component.html',
        {
          multiple: true,
          onShowing: function (scope, element, options, controller) {
            scope.action = action;
            scope.DialogService = d;
            scope.tryAgain = (process, processId) => {
              this.API.all(`action-process/${process}/${processId}/try`)
                  .customPUT()
                  .then(response => {
                    parent.getOrderDetail(parent.orderId);
                    scope.DialogService.$mdDialog.hide()
                  })
            }
            scope.cancel = () => {
              scope.DialogService.$mdDialog.hide()
            }
          }
        }
    ).catch(err => {
      console.log(err);
    });
  }
  erpLogs(logs) {
    let d = this.DialogService;
    this.DialogService.fromTemplateV2(
        './views/angular/app/components/order-lists/partials/erp-logs.component.html',
        {
          multiple: true,
          onShowing: function (scope, element, options, controller) {
            scope.logs = logs;
            scope.DialogService = d;
            scope.cancel = () => {
              scope.DialogService.$mdDialog.hide()
            }
          }
        }
    ).catch(err => {
      console.log(err);
    });
  }


  createReturnedOffer(partialReturned, note = '', offerId = null, component) {
    let d = this.DialogService;
    this.convertToOffer = this.orderDetail.orderDetail.ts_order_code;
    let convertToOffer = this.orderDetail.orderDetail.ts_order_code;
    if (offerId){
      this.convertToOffer = '_'
    }
    if (!this.convertToOffer) {
      this.ToastService.showHtml('warn', this.$translate.instant('RETURN_NUMBER_REQUIRED'))
      return;
    }

    this.DialogService.fromTemplateV2(
        './views/angular/app/components/invoices/partials/dialog/return-confirm.html',
        {
          onShowing: function (scope, element, options, controller) {
            scope.DialogService = d;
            scope.transactionId = convertToOffer;
            scope.component     = component;
            scope.partialReturned = partialReturned;
            scope.cancel = ()=>{
              scope.DialogService.$mdDialog.hide(false)
            }
            scope.ok = ()=>{
              scope.DialogService.$mdDialog.hide(true)
            }
          }
        }
    ).then(ok =>{
      if (ok){
        this.API.all("action-process/returned/" + this.convertToOffer)
            .customDELETE(null, {
              partialReturned: partialReturned ? 1 :0,
              note: note,
              offerId: offerId
            }).then((response) => {
          if (response.response.status === 207) {
            this.ToastService.showHtml('success', this.$translate.instant('RETURN_CREATED'))
          }
        })
      }
    })
        .catch(err => {
      console.log(err)
    });
  }
  downloadPdf(orderId) {
    this.API.all('order/detail?output=pdf')
        .withHttpConfig({ responseType: 'arraybuffer' })
        .post({orderId: orderId})
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' });
          this.previewPdf(blob)
        })
  }
  previewPdf(blob) {
    var options = {
      controller        : 'PrintPreviewDialogController',
      controllerAs      : 'vm',
      //  scope             : this.$scope,
      locals            : {
        blob:blob
      },
      bindToController  : true,
      multiple          : true,
      onShowing         : function (scope, element, options, controller) {
        controller.previewBlob(blob);
      },
    };

    var customerDialogPromise = this.DialogService.fromTemplateV2(
        './views/angular/dialogs/printPreview/printPreview.dialog.html',
        options
    );
    customerDialogPromise.catch(err=>{
      console.log(err)
    })

  }

  transfer(sipNo) {
    this.API.all("integration/exportOrder")
        .post({sip_no: sipNo})
        .then((response) => {
          if (response !== null && response.type === "redirect") {
            this.$auth.logout();
            this.state.go("app.login");
          }

          if (response.error === false) {
            this.ToastService.success(
                this.$translate.instant("ORDER_TRANSFERED_ERP")
            );
          }

          if (response.error === true) {
            this.ToastService.error(
                this.$translate.instant("ORDER_NOT_TRANSFERED_ERP")
            );
          }
        })
        .catch(function () {

        });
  }
  changeCurrency(){
    this.isOrderCurrency = !this.isOrderCurrency;
  }
  orderStatusHistory(history){
    let d = this.DialogService;
    this.DialogService.fromTemplateV2(
        './views/angular/app/components/order-lists/partials/order-status-history.component.html',
        {
          multiple: true,
          onShowing: function (scope) {
            scope.orderStatusHistory = history;
            scope.DialogService = d;
            scope.cancel = () => {
              scope.DialogService.$mdDialog.hide()
            }
          }
        }
    ).catch(err => {
      console.log(err);
    });
  }
  calculateRowColors() {
    let parent = this;
    let firstAction = this.orderDetail.orderDetail.orderLineActionHistory[0];
    let lastAction = this.orderDetail.orderDetail.orderLineActionHistory[this.orderDetail.orderDetail.orderLineActionHistory.length - 1];
    angular.forEach(this.orderDetail.orderDetail.products, function (product) {
      let firstItem = firstAction.items.find(function (item) {
        return item.barcode === product.ts_barcode;
      });
      let firstQuantity = firstItem ? firstItem.quantity : 0;
      let lastItem = lastAction.items.find(function (item) {
        return item.barcode === product.ts_barcode;
      });
      let lastQuantity = lastItem ? lastItem.quantity : 0;
      if (firstQuantity === lastQuantity) {
        //beyaz
        product.tr_class = 'invoice-detail-tr';
        product.div_class = 'icon-background';
        product.status_description = '';
      } else if (lastQuantity === 0) {
        //kırmızı
        product.tr_class = 'invoice-detail-tr-delete';
        product.div_class = 'icon-background-delete';
        product.status_description = parent.$translate.instant('REMOVED_PRODUCT');
      } else if (firstQuantity === 0 && lastQuantity > 0) {
        //yeşil
        product.tr_class = 'invoice-detail-tr-add';
        product.div_class = 'icon-background-add';
        product.status_description = parent.$translate.instant('ADDED_PRODUCT');
      } else {
        //sarı
        product.tr_class = 'invoice-detail-tr-change';
        product.div_class = 'icon-background-change';
        product.status_description = parent.$translate.instant('MODIFIED_QUANTITY_PRODUCT');
      }
    });
  };

  }
InvoicesDetailController.$inject = ["API", "ToastService", "$timeout", "i18nService", "$auth", "$state", "DialogService", "UserService", "$templateCache", "$scope", "$stateParams", "$translate","$location","$rootScope","$mdBottomSheet"];
const InvoicesDetailComponent = {
  templateUrl: "./views/angular/app/components/invoices-detail/invoices-detail.component.html",
  controller: InvoicesDetailController,
  controllerAs: "vm",
  bindings: {
    orderId: '<',
  },
};

export {InvoicesDetailComponent,InvoicesDetailController}
